import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import './CommonStyles.css';
import "semantic-ui-css/semantic.min.css"
import * as Utils from '../utils/Utils.js'; 
import * as AuthUtils from '../utils/AuthUtils.js';
import * as ReactUtils from "../utils/ReactUtils.js";

const GPTHistoryPage = () => {
  // State to store the list of translations
  const [gptHistory, setGPTHistory] = useState([]);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => { 
    updateUserSignedInStateAndEmail(); 
  }, []);

  const updateUserSignedInStateAndEmail = async () => {    
    const accessToken = await Utils.getUserAccesstoken();
    if (accessToken) {
      setIsUserSignedIn(true);

      const userInfo = await AuthUtils.currentUserInfo();
      if (userInfo?.attributes?.email) {
        setUserEmail(userInfo?.attributes?.email);
      }

      const subscriptionState = await Utils.getSubscriptionState(); // Utils.SubscriptionState.PREMIUM
      setIsUserSubscribed(Utils.isUserSubscribed(subscriptionState));
      // setIsUserSubscribed(true);
      if (!Utils.isUserSubscribed(subscriptionState)) {
        ReactUtils.PurchasePremiumPopup(document.getElementById("historyList"), userEmail);
      }

      const gptHistory = await Utils.getGPTHistory();
      setGPTHistory(gptHistory.reverse());
    } else {
      ReactUtils.showUserSignInOrOutPopup(null, document.getElementById("historyList"), true, updateUserSignedInStateAndEmail);
    }
  };

  async function clear() {
    await Utils.clearGPTHistory();
    const gptHistory = await Utils.getGPTHistory();
    setGPTHistory(gptHistory);
  }

  return (
    <div>
    <meta charSet="utf-8" />
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/water.css@2/out/water.css"></link>
    <title>WorkGPT</title>

    <style type="text/css">
    </style>

    <div className="ui container">
      <Header></Header>

      <div>
        <div>
          <h2 style={{ display: 'inline'}} >GPT History</h2>
          {isUserSignedIn && isUserSubscribed && 
            <button className="ui button" onClick={clear} style={{ display: 'inline', float: 'right'}}>
              Clear all GPT history</button>}
        </div>
        <div id="historyList" style={{marginTop: '20px', minHeight: '400px'}}> {isUserSignedIn && 
          (isUserSubscribed 
              ? (gptHistory?.length === 0 ? <div>No GPT History available</div>
                : <ul>
                  {gptHistory.map((history, index) => (
                    <li key={index}>
                      {history.gptResponse}<br />
                      <div style={{color: 'gray'}}> {history.timestamp} <br /></div>
                      <div style={{color: 'gray'}}></div><br />
                    </li>
                  ))}
                </ul>)
              : <div style={{color: 'red'}}>Please Upgrade to see GPT Response history!</div>)
          }
        </div>
      </div>

      <Footer></Footer>
    </div>

  </div>
  );
}

export default GPTHistoryPage;