import { AwsClient } from 'aws4fetch'
import * as AuthUtils from './AuthUtils.js';
import { LANGUAGE_MAP } from './LanguageMap.js';

export const MONTHLY_PREMIUM_SUBSCRIPTION_PAYMENT_LINK = "https://buy.stripe.com/cN23ek85vgZLdTaaF5?prefilled_email="; //$6.99 and $3.99 link is "https://buy.stripe.com/fZebKQ0D35h32as6op";
export const YEARLY_PREMIUM_SUBSCRIPTION_PAYMENT_LINK = "https://buy.stripe.com/eVa4iobhHdNz16o3cH?prefilled_email=" // "https://buy.stripe.com/00g0280D3fVH7uM28B"; // $59.99 and $34.99  "https://buy.stripe.com/bIYbKQ0D3dNz9CU28a"; // 

export const MONTHLY_ENTERPRISE_SUBSCRIPTION_PAYMENT_LINK = "https://buy.stripe.com/00g2ag5XnbFrbL23cn?prefilled_email=";
export const YEARLY_ENTERPRISE_SUBSCRIPTION_PAYMENT_LINK = "https://buy.stripe.com/bIY9CI3Pf38V4iA6oA?prefilled_email=";

const USER_SUBSCRIPTION_URL = "https://exoucp4btbcl3z6gsggbnsqize0mjmaw.lambda-url.us-west-2.on.aws";
//const TRANSLATE_URL_TEST = "https://59lb2r4nzh.execute-api.us-west-2.amazonaws.com/dev";
const AWS_ACCESS_KEY = "AKIART2LN3C25LZDUTDG";
const AWS_SECRET_KEY = "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";
const AWS_REGION = "us-west-2";
const AWS_SERVICE = "lambda"
export const awsClient = new AwsClient({ accessKeyId: AWS_ACCESS_KEY, 
  secretAccessKey: AWS_SECRET_KEY, region: AWS_REGION, service: AWS_SERVICE});

export const AI_MODEL = {
  GPT35: 'GPT-3.5',
  GPT4: 'GPT-4',
  PaLM2: 'PaLM-2',
  LIAMA: 'Llama-2-70b'
};

export const AI_MODEL_Text_Map = new Map([
  [AI_MODEL.GPT35, 'ChatGPT'], 
  [AI_MODEL.PaLM2, 'Google Bard PaLM 2'], 
  //[AI_MODEL.GPT4, 'Latest GPT-4 model'],
  [AI_MODEL.LIAMA, 'Meta Llama 2 (70b)'],
]);

export const AI_CREATIVNESS_Map = new Map([
  ["Very Low Creativity (0)", 0],
  ["Low Creativity (0.2)", 0.2], 
  ["Normal (0.6)", 0.6], 
  ["High Creativity (0.8)", 0.8], 
  ["Very High Creativity (1.0)", 1.0], 
]);

export const PLATFORM_TYPE = {
  WEB: "Web",
  CHROME_EXT: "ChromeExt",
  EDGE_EXT: "EdgeExt",
  SAFARI_EXT: "SafariExt",
  IOS: "iOS",
  ANDROID: "Android",
  OTHER: "Other"
}

export const DEFAULT_GPT_ROLE = "Default General";
export const GPT_ROLE_ARRAY = [
  DEFAULT_GPT_ROLE, 'AI Assistant', 'Writing Assistant', 'Customer Support', 'Recruiter', 'Marketing Expert', 
  'Professional Salesperson', 'HR', 'Academic Researcher', 'Financial Advisor', 'Language Tutor', 
  'Language Tutor', 'Product Manager', 'Engineer', 'Manager', 'Director', 'CEO', 'Startup Lawyer', 
  'Tech Writer'
];

export const PURCHASE_PLAN = {
  PREMIUM: 'Premium',
  ENTERPRISE: 'Enterprise',
};

export const ACTION_TO_TAKE = {
  ACTION_CUSTOM_FREEFORM: "Custom freeform action",
  ACTION_REWRITE: "Improve writing and rewrite",
  ACTION_FIX_GRAMMAR: "Fix grammar and spelling",
  ACTION_REPLY_TO: "Reply to",
  ACTION_CONTINUE_WRITING: "Continue writing",
  ACTION_HELP_ME_UNDERSTAND: "Help me understand",
  ACTION_GENERATE_TASK_LIST: "Create task list from it",
  ACTION_ELABORATE: "Elaborate",
  ACTION_POLISH: "Polish",
  ACTION_EXPAND: "Expand and make longer",
  ACTION_SHORTEN: "Shorten",
  ACTION_TRANSLATE: "Translate to",
  ACTION_SUMMARIZE: "Summarize the content",
  ACTION_EXPLAIN: "Explain the content",
  ACTION_DETECT_LANGUAGE: "Detect language",
  ACTION_DIFFERENT_WAYS_TO_SAY_THIS: "Different ways to say this",
  ACTION_PROVIDE_SUGGESTIONS: "Suggestions to the content",
  ACTION_BULLETIZE: "Bulletize",
  ACTION_SYNONYMS: "Synonyms",
  ACTION_DICTIONARY: "Dictionary",
  ACTION_FORMAT: "Format",

  ACTION_GENERATE_NOTES: "Create notes of it",
  ACTION_MAKE_EMAIL: "Make it an email",
  ACTION_MAKE_CALENDAR_NOTE: "Make it a calendar note",
  ACTION_GENERATE_TO_DO: "Generate To-Do List from it",
  ACTION_MAKE_REPORT: "Make it a report",
  ACTION_MAKE_SUMMARY_REPORT: "Make it a summary report",
  ACTION_MAKE_STATUS_UPDATE: "Make it a status update", 
  ACTION_MAKE_POST: "Make it a post",
  ACTION_MAKE_MARKETING_CONTENT: "Make it marketing content",
  ACTION_MAKE_SEO: "Make it SEO-optimized content",
  ACTION_MAKE_SEO_ARTICLE: "Make it SEO-optimized article",
  ACTION_MAKE_DESCRIPTION: "Make it a description",
  ACTION_MAKE_PRODUCT_DESCRIPTION: "Make it a product description",
  ACTION_MAKE_TWEET: "Make it a tweet",
  ACTION_MAKE_BLOG: "Make it a blog",
  ACTION_MAKE_FACEBOOK_POST: "Make it a Facebook post",
  ACTION_MAKE_LINKEDIN_POST: "Make it a Linkedin post",
  ACTION_MAKE_INSTAGRAM_POST: "Make it a Instagram post",
  ACTION_MAKE_VIDEO_POST: "Make it a video script",
  ACTION_MAKE_TIKTOK_VIDEO_POST: "Make it a Tiktok video script",
  ACTION_MAKE_YOUTUBE_VIDEO_POST: "Make it a Youtube video script",

  ACTION_OTHER: "Other",
}

export const CREATE_CONTENT_ACTION_SUGGESTIONS = {
  "ActionCreateCustom": "Write  ",
  "ActionCreateEmail": "Create an email about ",
  "ActionCreateEmailReply": "Create an email reply about ",
  "ActionCreateNote": "Write a note ",
  "ActionCreateCalendarNote": "Write a calendar note ",
  "ActionCreateActionItem": "Create an action item ",
  "ActionCreateTaskList": "Create task list ",
  "ActionCreateSEO": "Write a SEO-optimized content about ",
  "ActionCreateSEOArticle": "Write a SEO-optimized article about ",
  "ActionCreateDescription": "Write a description about ",
  "ActionCreateProductDescription": "Write product description about ",
  "ActionCreateMarketingContent": "Create marketing content about ",
  "ActionCreateMessage": "Create a message about ",
  "ActionCreateMessageReply": "Create a message reply about ",
  "ActionCreateComment": "Create a comment about ",
  "ActionCreateProfile": "Create a profile about ",
  "ActionCreateReport": "Write a report about ",
  "ActionCreateSummaryReport": "Write a summary report about ",
  "ActionCreateStatusUpdate": "Create a status update about ", 
  "ActionCreatePost": "Write a post about ",
  "ActionCreateWriteEssay": "Write an essay on ",
  "ActionCreateResearchContent": "Write research content about ",
  "ActionCreateTweet": "Write a tweet about ",
  "ActionCreateABlog": "Write a blog about ",
  "ActionCreateFacebookPost": "Write a Facebook post about ",
  "ActionCreateLinkedinPost": "Write a Linkedin post about ",
  "ActionCreateInstagramPost": "Write a Instagram post about ",
  "ActionCreateVideoCaption": "Write a video caption about ",
  "ActionCreateVideoPost": "Write a video script about ",
  "ActionCreateTiktokVideoPost": "Write a Tiktok video script about ",
  "ActionCreateYoutubeVideoPost": "Write a Youtube video script about ",
  "ActionCreateJobPost": "Write a job post about ",
  "ActionCreateResume": "Write a resume about ",
}

const TONE_SUGGESTIONS = ["None", "Professional", "Friendly", "Causal", "Formal", 
  "Enthusiastic", "Funny", "Positive", "Urgent", "Informational", "Empathetic", "Vivid", "Concise", "Persuasive", 
  "Serious", "Optimistic", "Pessimism", "Worried", "Curious", "Assertive", "Encouraging", "Surprised", 
  "Polite", "Respectful", "Cooperative", "Decisive", "Cheerful", "Emotional", "Humor", "Sarcastic", 
  "Appreciative", "Entertaining", "Courageous", "Very Positive", "Very Friendly", "Very Formal", 
  "Very Urgent", "Very Enthusiastic"];
const WORD_SUGGESTIONS = ["None", "50", "10", "20", "30", "100", "200", "300", "500"];
const LANGUAGE_SUGGESTIONS = ['Arabic', 'English', 'Chinese (Simplified)', 'Chinese (Traditional)', 
'French', 'German', 'Hindi', 'Indonesian', 'Italian', 'Latin', 'Polish', 'Portuguese (Portugal, Brazil)', 
'Romanian', 'Russian', 'Spanish', 'Swedish', 'Thai', 'Vietnamese'];
export const LENGTH_SUGGESTIONS = ["Auto", "Short", "Medium", "Long"];

export const INITIAL_LEFT_CREDITS = 10;
export const default_i18n = 'en'
export const DEFAULT_MAIN_MENU_ID_SELECTED = "editOrReadContentMenuId";
export const DEFAULT_ACTION_SELECTED = ACTION_TO_TAKE.ACTION_REWRITE;
export const DEFAULT_ADDITIONAL_CONTEXT = "";
export const DEFAULT_AI_MODEL = AI_MODEL.GPT35;

export const SETTINGS_LAST_ACTIVE_MENU_ID = "settings_lastActiveMenuId";
export const SETTINGS_LAST_ACTION_SELECTED = "settings_lastActionSelected";
export const SETTINGS_AUTO_SAVED_INPUT_CONTENT = "settings_autoSavedInputContent";
export const SETTINGS_LAST_ADDITIONAL_CONTEXT = "settings_lastAdditionalContext";
export const SETTINGS_GPT_MODEL_TO_USE = "settings_gptModelToUse";
export const SETTINGS_USER_GPT_KEY = "settings_userGPTKey";

export const SETTINGS_GPT_RESPOND_LANGUAGE = "settings_gptRespondLanguage";
export const DEFAULT_RESPOND_LANGUAGE = "auto";

export const SETTIGNS_GPT_CREATIVENESS = "settings_gptCreativeness";
export const DEFAULT_CREATIVENESS = "0.6";

export const SETTINGS_GPT_ROLE_AS = "settings_gptRoleAs";
export const SETTINGS_GPT_CUSTOM_KNOWLEDGE = "settings_gptCustomKnowledge";

export const LAST_GPT_REQUEST_TIMESTAMP = "lastGPTRequestTimestamp";
export const GPT_REQUEST_MADE_TODAY = "gptRequestMade";
export const SUBSCRIPTION_STATE = "subscriptionState";
export const LEFT_GPT_CREDITS = "leftGPTCredits";

export const SubscriptionState = {
  NONE: 'None',
  PREMIUM: 'Premium',
  ENTERPRISE: 'Enterprise'
};

export async function getUserAccesstoken() {
  const user = await AuthUtils.currentAuthenticatedUser();
  if (user) {
    const session = await AuthUtils.currentSession();
    return session?.getAccessToken()?.getJwtToken();
  }

  return null;
}

export function isUserSubscribed(subscriptionState) {
  return subscriptionState === SubscriptionState.PREMIUM
    || subscriptionState === SubscriptionState.ENTERPRISE;
}

export function setLocalStorageProperty(key, value) {
  localStorage.setItem(key, value);
}

export function getLocalStorageProperty(key) {
  return localStorage.getItem(key);
}

export function removeLocalStorageProperty(key) {
  localStorage.removeItem(key);
}

export async function getUsernamePrefix() {
  const userInfo = await AuthUtils.currentUserInfo();
  return userInfo?.attributes?.name + "_";
}

export function setLastGPTRequestTimeStamp() {
  setLocalStorageProperty(LAST_GPT_REQUEST_TIMESTAMP, Date.now());
}

export function getLastGPTRequestTimeStamp() {
  return getLocalStorageProperty(LAST_GPT_REQUEST_TIMESTAMP);
}

export function setSubscriptionState(subscriptionState) {
  const expirationMinutes = (isUserSubscribed(subscriptionState) ? 1440 : 60);
  const expirationTime = new Date().getTime() + expirationMinutes * 60 * 1000;
  // Store the cache value along with the expiration time
  const dataWithExpiration = { value: subscriptionState, expiration: expirationTime };
  //console.log("setSubscriptionState()", dataWithExpiration);
  setLocalStorageProperty(SUBSCRIPTION_STATE, dataWithExpiration);
}

export async function getSubscriptionState() {
  const userAccessToken = await getUserAccesstoken();
  if (!userAccessToken) {
    return SubscriptionState.NONE;
  }
  const cachedSubscriptionState = getLocalStorageProperty(SUBSCRIPTION_STATE);
  //console.log("getSubscriptionState()", cachedSubscriptionState[SUBSCRIPTION_STATE].expiration, 

  // Check if the cache value exists and is not expired
  if (cachedSubscriptionState && cachedSubscriptionState[SUBSCRIPTION_STATE]?.expiration 
    && cachedSubscriptionState[SUBSCRIPTION_STATE].expiration >= new Date().getTime()) {
    return cachedSubscriptionState[SUBSCRIPTION_STATE].value;
  } else {
    return await fetchSubscriptionStateFromBackend(userAccessToken);
  }
}

export async function fetchSubscriptionStateFromBackend(userAccessToken) {
  const requestData = {
    accessToken: userAccessToken
  };

  const response = await awsClient.fetch(`${USER_SUBSCRIPTION_URL}/my-lambda/invocations`, 
    { body: JSON.stringify(requestData) });
  const subscriptionResponse = await response.json();
  const subscriptionState = subscriptionResponse.subscriptionState;
  setSubscriptionState(subscriptionState);

  return subscriptionState;
}

export async function getIsExcceedRequestLimitEveryMin() {
  if (getIsSkipExcceedRequestLimitEveryMin()) {
    return false;
  }

  const subscriptionState = await getSubscriptionState();
  if (isUserSubscribed(subscriptionState)) {
    return false;
  }

  const lastTranslationTimestamp = getLastGPTRequestTimeStamp();
  if (lastTranslationTimestamp && (Date.now() - lastTranslationTimestamp) < 20000) {
    return true;
  }

  return false;
}

export function getIfReachDailyRequestLimit(subscribeState) {
  // Assuming USER_PROPERTY_GPT_REQUEST_TODAY is a variable holding the key for the user property
  // Initialize an empty map if no existing map is found
  const requestTodayJson = getLocalStorageProperty(GPT_REQUEST_MADE_TODAY);
  var requestTodayMap = requestTodayJson ? JSON.parse(requestTodayJson) : {};
  var currentDateString = new Date().toLocaleDateString(); 
  
  //console.log("requestToday Map", requestTodayMap);
  if (requestTodayMap[currentDateString]) {
    if (subscribeState === SubscriptionState.ENTERPRISE) {
      return requestTodayMap[currentDateString] > 1800;
    } else if (subscribeState === SubscriptionState.PREMIUM) {
      return requestTodayMap[currentDateString] > 500;
    } else {
      return requestTodayMap[currentDateString] > 30;
    } 
  }
}

export function getIsSkipExcceedRequestLimitEveryMin() {
  const requestTodayJson = getLocalStorageProperty(GPT_REQUEST_MADE_TODAY);
  var requestTodayMap = requestTodayJson ? JSON.parse(requestTodayJson) : {};
  var currentDateString = new Date().toLocaleDateString(); 

  return requestTodayMap[currentDateString] < 3;
}
  
export async function increaseDailyRequest() {
  // Initialize an empty map if no existing map is found
  const requestTodayJson = getLocalStorageProperty(GPT_REQUEST_MADE_TODAY);
  var requestTodayMap = requestTodayJson ? JSON.parse(requestTodayJson) : {};
  const currentDateString = new Date().toLocaleDateString(); 

  if (requestTodayMap[currentDateString]) {
    requestTodayMap[currentDateString] = requestTodayMap[currentDateString] + 1;
  } else {
    // The first request of today, will clear the map before.
    requestTodayMap = {};
    requestTodayMap[currentDateString] = 1;
  }

  // console.log("Daily Translation Request made", requestTodayMap[currentDateString]);

  var updatedrequestJson = JSON.stringify(requestTodayMap);
  setLocalStorageProperty(GPT_REQUEST_MADE_TODAY, updatedrequestJson);
}

export function getNumberOfLeftGPTCredits() {
  const leftCreditsResult = getLocalStorageProperty(LEFT_GPT_CREDITS);
  // Todo: move the left credits logic to the server side. 
  if (leftCreditsResult === null || leftCreditsResult === undefined || leftCreditsResult > 20) {
    return INITIAL_LEFT_CREDITS;
  }

  return leftCreditsResult;
}

export function decrementNumberOfLeftCredits(decrement = 1) {
  var leftCredits = getNumberOfLeftGPTCredits();
  leftCredits = leftCredits > decrement ? (leftCredits - decrement) : 0;
  setLocalStorageProperty(LEFT_GPT_CREDITS, leftCredits);

  return leftCredits;
}

export function showLoadingDimmer($dimmer) {
  $dimmer?.classList.add("active");
}

// export function to hide the dimmer
export function hideLoadingDimmer($dimmer) {
  $dimmer?.classList.remove("active");
}

export function addSelectOptionToElement(selectElement, value, text, previousSelected) {
  const newOption = document.createElement("option");
  newOption.value = value; 
  newOption.text = text; 

  selectElement.appendChild(newOption);

  if (previousSelected === value) {
    selectElement.value = value;
  }
}

export function initGPTModelElement(gptModelToUse, $gptModelSelection) {
  $gptModelSelection.innerHTML = '';
  for (const [aiModel, aiModelText] of AI_MODEL_Text_Map) {
    //console.log("aiModel and aiModelText is", aiModel, aiModelText);
    addSelectOptionToElement($gptModelSelection, aiModel, aiModelText, gptModelToUse)
  }
}

export function initTranslateLanguageElement(languageTranslatedTo, $translateLanguageSelection) {
  $translateLanguageSelection.innerHTML = '';
  LANGUAGE_MAP.forEach((language) => {
    addSelectOptionToElement($translateLanguageSelection, language.val, language.text, languageTranslatedTo);
  });
}

export function initGPTCreativnessElement(creativeness, $creativenessSelection) {
  $creativenessSelection.innerHTML = '';
  for (const [creativnessText, creativnessValue] of AI_CREATIVNESS_Map) {
    addSelectOptionToElement($creativenessSelection, creativnessValue, creativnessText, creativeness)
  }
}

export function initGPTRoleAs(gptRole, $gptRoleAsSelection) {
  $gptRoleAsSelection.innerHTML = '';
  for (var role of GPT_ROLE_ARRAY) {
    addSelectOptionToElement($gptRoleAsSelection, role, role, gptRole);
  }
}

export function hideHTMLElement($element) {
  if ($element) {
    $element.style.display = "none";
  }
}

export function showHTMLElement($element) {
  if ($element) {
    $element.style.display = "block";
  }
}

export function getAdditionalContextSuggestions(actionSelected) {
  if (actionSelected === ACTION_TO_TAKE.ACTION_CONTINUE_WRITING) {
    return WORD_SUGGESTIONS;
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_REWRITE) {
    return TONE_SUGGESTIONS;
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_TRANSLATE) {
    return LANGUAGE_SUGGESTIONS;
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_EXPAND) {
    return WORD_SUGGESTIONS;
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_SHORTEN) {
    return WORD_SUGGESTIONS;
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_REPLY_TO) {
    return TONE_SUGGESTIONS;
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_SUMMARIZE) {
    return WORD_SUGGESTIONS;
  } 

  return [];
}

export function getAdditionalContextHint(actionSelected) {
  if (actionSelected === ACTION_TO_TAKE.ACTION_CONTINUE_WRITING) {
    return "3. Additional context: enter number of words you want to continue writing";
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_REWRITE) {
    return "3. Additional context: any the tone you want, e.g. Professional, Causal, Friendly, Formal etc";
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_TRANSLATE) {
    return "3. Additional context: enter the language you want, Example: Spanish";
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_EXPAND) {
    return "3. Additional context: enter number of words you want to expand to";
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_SHORTEN) {
    return "3. Additional context: enter number of words you want to shorten to";
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_REPLY_TO) {
    return "3. Additional context: any tone you want, e.g. Professional, Causal, Friendly, Formal etc"
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_SUMMARIZE) {
    return "3. Additional context: enter number of words you want to summarize to";
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_OTHER || actionSelected === ACTION_TO_TAKE.ACTION_CUSTOM_FREEFORM) {
    return "3. What action to take or any question you want to ask";
  } else if (actionSelected === ACTION_TO_TAKE.ACTION_FORMAT) {
    return "3. Additional context: enter how to format it, e.g. upper case, lower case etc.";
  }

  return "3. Any context, details, information, requirements etc to add (optional)";;
}


export function getGPTPromptFromInput(textInput, action, additionalInfoInputForAction) {
  // console.log("action to take", textInput, action, additionalInfoInputForAction);
  var prompt = textInput;
  if (!textInput) {
    return '';
  }

  if (action === ACTION_TO_TAKE.ACTION_FIX_GRAMMAR) {
    prompt = "Please correct the spelling and grammar mistakes of the following text without making any improvements or changes to the original meaning or style. In other words, only correct spelling, syntax, or grammar mistakes, do not make improvements. Do not wrap responses in quotes. Show the corrections in bold so I can see what has been corrected. :```" + textInput + "```.";
  } else if (action === ACTION_TO_TAKE.ACTION_CONTINUE_WRITING) {
    var numberOfWords = 100; 
    if (additionalInfoInputForAction) {
      numberOfWords = additionalInfoInputForAction;
    }
    prompt = "Continue Writing " + numberOfWords + " words for: ```" + textInput + "```";
  } else if (action === ACTION_TO_TAKE.ACTION_REWRITE) {
    if (additionalInfoInputForAction) {
      prompt = "Rewrite in " + additionalInfoInputForAction + " tone for ```" + textInput + "```";
    } else {
      prompt = "Improve writing and rewrite ```" + textInput + "```";
    }
  } else if (action === ACTION_TO_TAKE.ACTION_TRANSLATE) {
    prompt = "Translate to " + additionalInfoInputForAction + " for ```" + textInput + "```";
  } else if (action === ACTION_TO_TAKE.ACTION_EXPAND) {
    if (additionalInfoInputForAction) {
      prompt = "Expand to " + additionalInfoInputForAction + " words for ```" + textInput + "```";
    } else {
      prompt = "Expand to more words for ```" + textInput + "```";
    }
  } else if (action === ACTION_TO_TAKE.ACTION_SHORTEN) {
    if (additionalInfoInputForAction) {
      prompt = "Shorten to " + additionalInfoInputForAction + " words for ```" + textInput + "```";
    } else {
      prompt = "Shorten words for ```" + textInput + "```";
    }
  } else if (action === ACTION_TO_TAKE.ACTION_REPLY_TO) {
    if (additionalInfoInputForAction) {
      prompt = "Write a response in " + additionalInfoInputForAction + " tone to ```" + textInput + "```";
    } else {
      prompt = "Write a response to ```" + textInput + "```";
    }
  } else if (action === ACTION_TO_TAKE.ACTION_POLISH) {
    if (additionalInfoInputForAction) {
      prompt = "Improve and polish " + additionalInfoInputForAction + " ```" + textInput + "```";
    } else {
      prompt = "Improve and polish for ```" + textInput + "```";
    }
  } else if (action === ACTION_TO_TAKE.ACTION_SUMMARIZE) {
    if (additionalInfoInputForAction) {
      prompt = "Summarize text content to " + additionalInfoInputForAction + " words for ```" + textInput + "```";
    } else {
      prompt = "Provide a summary for the following text content ```" + textInput + "```";
    }
  } else if (action === ACTION_TO_TAKE.ACTION_EXPLAIN) {
    if (additionalInfoInputForAction) {
      prompt = "Explain in details " + additionalInfoInputForAction + " for : ```" + textInput + "```";
    } else {
      prompt = "Explain in details for ```" + textInput + "```";
    }
  } else if (action === ACTION_TO_TAKE.ACTION_OTHER || (action === ACTION_TO_TAKE.ACTION_CUSTOM_FREEFORM)) {
    prompt = additionalInfoInputForAction + " ";
    if (textInput) {
      prompt += " ```" + textInput + "```";
    }
  } else if (action === ACTION_TO_TAKE.ACTION_ELABORATE) {
    prompt = "Elaborate  " + (additionalInfoInputForAction ?? '') + " for ```" + textInput + "```";
    // console.log("prompt for elaborate is ", prompt);
  } else if (action === ACTION_TO_TAKE.ACTION_BULLETIZE) {
    prompt = "Bulletize  " + (additionalInfoInputForAction ?? '') + " for ```" + textInput + "```";
  } else if (action === ACTION_TO_TAKE.ACTION_SYNONYMS) {
    prompt = "Get the Synonyms of  " + (additionalInfoInputForAction ?? '') + " for ```" + textInput + "```";
  } else if (action === ACTION_TO_TAKE.ACTION_DICTIONARY) {
    prompt = "Look up in the dictionary with all details  " + (additionalInfoInputForAction ?? '') + " for ```" + textInput + "```";
  } else if (action === ACTION_TO_TAKE.ACTION_PROVIDE_SUGGESTIONS) {
    prompt = "Provide suggestions  " + (additionalInfoInputForAction ?? '') + " to the following content: ```" + textInput + "```";
  } else if (action === ACTION_TO_TAKE.ACTION_DIFFERENT_WAYS_TO_SAY_THIS) {
    prompt = "Provide different ways " + (additionalInfoInputForAction ?? '') + " to say the following content: ```" + textInput + "```";
  } else if (action === ACTION_TO_TAKE.ACTION_DETECT_LANGUAGE) {
    prompt = "Detect and get the language  " + (additionalInfoInputForAction ?? '') + " for ```" + textInput + "```";
  } else if (action === ACTION_TO_TAKE.ACTION_FORMAT) {
    prompt = "Format  " + (additionalInfoInputForAction ?? '') + " for ```" + textInput + "```";
  } else {
    prompt = action + " " + (additionalInfoInputForAction ?? '') + " for \```" + textInput + "\```";
  }

  return prompt;
}

export function getPreSetPromptFromSettings() {
  var prompt = ""; 

  const gptRole = getLocalStorageProperty(SETTINGS_GPT_ROLE_AS) ?? DEFAULT_GPT_ROLE;
  if (gptRole && gptRole !== DEFAULT_GPT_ROLE) {
    prompt += "I want you to act as " + gptRole + ", ";
  }

  const customKnowledge = getLocalStorageProperty(SETTINGS_GPT_CUSTOM_KNOWLEDGE)
  if (customKnowledge) {
    prompt += "with this knowledge and context " + customKnowledge.substring(0, 1000) + ", ";
  }

  const gptRespondLanguage = getLocalStorageProperty(SETTINGS_GPT_RESPOND_LANGUAGE);
  if (gptRespondLanguage && gptRespondLanguage !== DEFAULT_RESPOND_LANGUAGE) {
    prompt += "please output in " + gptRespondLanguage + " language, "
  }

  return prompt;
}

export function hideUserGPTKey(gptKey) {
  if (gptKey.length >= 10) {
    // Extract the last six characters
    var lastSixCharacters = gptKey.slice(-10);
    var asterisks = '*'.repeat(gptKey.length - 10);
    return asterisks + lastSixCharacters;
  } else {
    return gptKey;
  }
}

export async function addToGPTHistory(gptResponse) {
  const newGPTResponse = {
    gptResponse: gptResponse,
    timestamp: new Date().toLocaleString(),
  };

  const userNamePrefix = await getUsernamePrefix();
  const existingGPTHistory = JSON.parse(getLocalStorageProperty(userNamePrefix + 'gptHistory')) || [];
  existingGPTHistory.push(newGPTResponse);

  // console.log("Add to GPT History", gptResponse);
  setLocalStorageProperty(userNamePrefix + 'gptHistory', JSON.stringify(existingGPTHistory));
}

// return GPT history as an array
export async function getGPTHistory() {
  // Retrieve the history from localStorage
  const userNamePrefix = await getUsernamePrefix();
  const gptHistory = JSON.parse(getLocalStorageProperty(userNamePrefix + 'gptHistory')) || [];
  return gptHistory;
}

export async function clearGPTHistory() {
  const userNamePrefix = await getUsernamePrefix();
  removeLocalStorageProperty(userNamePrefix + 'gptHistory');
}

export async function clearStoragePropertyAfterSignout() {
  clearLocalStorageKeysWithPrefix("settings_");
  const userNamePrefix = await getUsernamePrefix();
  clearLocalStorageKeysWithPrefix(userNamePrefix);
}

function clearLocalStorageKeysWithPrefix(prefix) {
  for (var key in localStorage) {
    if (key.startsWith(prefix)) {
      localStorage.removeItem(key);
    }
  }
}