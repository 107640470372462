import React from 'react';
import appLogo from "../images/workgpt_128.png";
import Header from './Header';
import Footer from './Footer';
import './CommonStyles.css';

const HelpCenterPage = () => {

  const faqData = [
    {
      question: 'What are benefits of Premium Service?',
      answer: `1. No limitation for credit usage of AI WorkGPT requests.
      2. 400 GPT requests every day for you to use!
      3. Best AI models to utilize, ChatGPT, Google Bard PaLM 2, Meta LIama 2 etc.
      4. Long GPT input supported with 25000 characters each time.
      5. Many great features to help you improve and create content and images, custom GPT and history etc
      6. Faster response with dedicated GPT server.
      7. Priority support for your issues and requests.
      8. Multiple surfaces supported to use anytime and anywhere, WebApp, Google Workspace addon, Chrome Extension.`,
    },
    {
      question: `After I upgrade to the Premium Service, could I cancel it if I no longer need it?`,
      answer: 'Yes, you could cancel the future subscription through the "manage subscriptions" button in the addon more action menu or directly contact us below. After that, you could follow the instructions and the subscription will be cancelled immediately afterward. If you cancel before the end of current subscription period, you will still be able to use your license, up to the last day of the subscription.',
    },
    {
      question: `Does data sent end up on OpenAI servers and contribute to ChatGPT's learning?`,
      answer: 'No, any data you interact with on our product is not used to enhance the learning of ChatGPT.  For further information, you can review OpenAI’s data usage policies at : https://openai.com/policies/api-data-usage-policies',
    },
    {
      question: `What is your refund policy?`,
      answer: `If you are encountering some issues using our services, please contact us and we will try our best to resolve it for you. If we can't resolve and fix the issue, we could discuss about a potential refund to you. `,
    },
  ];

  return (
    <div>
      <meta charSet="utf-8" />
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/water.css@2/out/water.css"></link>
      <title>WorkGPT</title>

      <style type="text/css">
        {Styles()}
      </style>

      <div className="ui container">
        <Header></Header>

        <div className="faq-container" style={{ marginBottom: '150px' }}>
          <div style={{ marginTop: '30px', marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
            <h1 className='common-font' style={{color: 'rgb(0, 122, 255)'}}>Help center</h1>
          </div>

          <div className='medium-text' style={{marginBottom: '40px', textAlign: 'center'}}>Please contact us at contact@aiworksuite.come if you cannot find an answer to your question.</div>
          
          <div className="faq-list">
            {faqData.map((item, index) => (
              <div className="faq-item" key={index}>
                <h3 className="question">{item.question}</h3>
                <p style={{ whiteSpace: 'pre-line' }} className="answer">{item.answer}</p>
              </div>
            ))}
          </div>
        </div>

        <Footer></Footer>
      </div>
    </div>
  );
};


const Styles = () => (
  <style>
    {`
      .faq-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
      }
      
      .faq-list {
        display: flex;
        flex-direction: column;
      }
      
      .faq-item {
        margin-bottom: 20px;
      }
      
      .question {
        margin: 0;
        color: #333;
      }
      
      .answer {
        margin: 5px 0 0;
        font-size: 15px;
        color: #666;
      }      

    `}
  </style>
);


export default HelpCenterPage;