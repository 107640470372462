import React, { useRef, useState, useEffect } from 'react';
import "@aws-amplify/ui-react/styles.css";
import "semantic-ui-css/semantic.min.css"
import { Link, useNavigate} from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react'
import { Hub } from 'aws-amplify';

import appLogo from "./images/workgpt_128.png";
import createContentDemo from "./images/createContent.gif"
import editContentDemo from "./images/editContent.gif"
import copyImage from "./images/copy.png";
import settingsImage from "./images/settings.png"
import historyImage from "./images/history.png";

//import {LANGUAGE_MAP} from "./utils/LanguageMap.js";
import * as AuthUtils from './utils/AuthUtils.js';
import * as ReactUtils from "./utils/ReactUtils.js";
import * as Utils from './utils/Utils.js';
import Footer from './ui_views/Footer';

const ASK_GPT_URL = "https://q2zizzhgylwast32c73gzlduj40widqx.lambda-url.us-west-2.on.aws";

const EDIT_OR_READ_CONTENT_MENU_ID = "editOrReadContentMenuId";
const CREATE_CONTENT_MENU_ID = "createContentMenuId";
const ASK_GPT_MENU_ID = "askGPTMenuId";
const CREATE_IMAGE_MENU_ID = "createImageMenuId";

//let lastActiveMenuItemId = EDIT_OR_READ_CONTENT_MENU_ID;

const INPUT_TEXT_PLACEHOLDER = {
  EDIT_READ_CONTENT: "Enter your text (e.g. look forward to see you.)",
  CREATE_CONTENT: "What to create? (e.g. Write a 200-word essay on global warming. Write a blog, tweet, LinkedIn post, or video caption about [])",
  ASK_GPT: "What questions do you want to ask WorkGPT directly?"
}

const WORKGPT_CHROME_URL = 'https://chrome.google.com/webstore/detail/workgpt-gpt-for-work/aijkbfcgfacnbgaladlemlbonnddphdf';
const WORKGPT_FOR_WORKSPACE_URL= "https://workspace.google.com/marketplace/app/workgpt_gpt_chatgpt_for_workspace/368806015202";
const EMAIL_AI_URL = "https://workspace.google.com/marketplace/app/emailai_gpt_chatgpt_ai_for_email_and_gma/244071321454";
const TRANSLATE_GPT_FOR_WORKSPACE_URL = "https://aiworksuite.com/translator-with-gpt";
const CONTACT_US_URL = "https://www.aiworksuite.com/contact-us"

function listenToSignUpSignInEvents() {
  Hub.listen('auth', async (data) => {
    // eslint-disable-next-line default-case
    switch (data.payload.event) {
      case 'signIn':
          console.debug('user signed in', data.payload.data?.attributes?.email);
          try {
            let user = await AuthUtils.currentAuthenticatedUser();
            if (!user.attributes['custom:origin_platform']) {
              let result = await AuthUtils.updateUserAttributes(user, { 'custom:origin_platform': Utils.PLATFORM_TYPE.WEB });
              console.debug('User attributes updated:', result);
            }
          } catch (error) {
              console.error('Error updating user orgin platform attribute:', error);
          }
          break;
      case 'signUp':
        console.debug('user signed up', data.payload.data?.attributes?.email);
        break;
      case 'signOut':
        console.debug('user signed out');
          break;
      case 'signIn_failure':
          console.log('user sign in failed');
          break;
      case 'configured':
          console.log('the Auth module is configured');
    }
  });  
}

export const HomePage = () => {
  listenToSignUpSignInEvents();
  const [inputTextValue, setInputTextValue] = 
    useState(Utils.getLocalStorageProperty(Utils.SETTINGS_AUTO_SAVED_INPUT_CONTENT) || '');
  const [lastActionSelected, setLastActionSelected] = useState(
      Utils.getLocalStorageProperty(Utils.SETTINGS_LAST_ACTION_SELECTED) ?? Utils.DEFAULT_ACTION_SELECTED);
  const [additionalContext, setAdditionalContext] = useState(
      Utils.getLocalStorageProperty(Utils.SETTINGS_LAST_ADDITIONAL_CONTEXT) ?? Utils.DEFAULT_ADDITIONAL_CONTEXT);
  const [additionalContextHint, setAdditionalContextHint]= useState(Utils.getAdditionalContextHint(lastActionSelected));
  const [additionalContextSuggestions, setAdditionalContextSuggestions] = useState(Utils.getAdditionalContextSuggestions(lastActionSelected));
  
  const handleInputTextChange = (event) => {    
    setInputTextValue(event.target.value); 
    Utils.setLocalStorageProperty(Utils.SETTINGS_AUTO_SAVED_INPUT_CONTENT, event.target.value);
  };
  const handleActionToTakeChange = (event) => {
    const actionSelected = event.target.value;
    setLastActionSelected(actionSelected);
    setAdditionalContext("");
    setAdditionalContextHint(Utils.getAdditionalContextHint(actionSelected));
    setAdditionalContextSuggestions(Utils.getAdditionalContextSuggestions(actionSelected));
    //console.log("additionalContextSuggestions", actionSelected, lastActionSelected, (Utils.getAdditionalContextSuggestions(actionSelected)));

    Utils.setLocalStorageProperty(Utils.SETTINGS_LAST_ACTION_SELECTED, actionSelected);
  };
  const handleAdditionalContextChange = (event) => {
    const additionalContext = event.target.value;
    setAdditionalContext(additionalContext);
    Utils.setLocalStorageProperty(Utils.SETTINGS_LAST_ADDITIONAL_CONTEXT, additionalContext);
  };

  const handleInputTextOnClick = (event) => {
    if (activeMenuItem === CREATE_CONTENT_MENU_ID) {
      const $suggestionDivElement = document.getElementById('createContentSuggestionsDivId');
      Utils.showHTMLElement($suggestionDivElement);
      event.stopPropagation();
    }
  }

  const [activeMenuItem, setActiveMenuItem] = useState(
    Utils.getLocalStorageProperty(Utils.SETTINGS_LAST_ACTIVE_MENU_ID) ?? Utils.DEFAULT_MAIN_MENU_ID_SELECTED);
  const handleMainMenuItemClick = (event) => {
    setActiveMenuItem(event.target.id);
    Utils.setLocalStorageProperty(Utils.SETTINGS_LAST_ACTIVE_MENU_ID, event.target.id);
  };

  const [activePurchasePlanShown, setActivePurchasePlanShown] = useState(Utils.PURCHASE_PLAN.PREMIUM);
  const handlePurchasePlanShownItemClick = (event) => {
    //console.log("activePurchasePlanShown", event.target.id);
    setActivePurchasePlanShown(event.target.id);
  };

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [gptOutputValue, setGptOutputValue] = useState(`Hello, please enter the text above and click the submit button for anything.`);
  //const gptOutputTextElementRef = useRef(null);
  const onCopyGPTOutputText = () => {
    navigator.clipboard.writeText(gptOutputValue);
  }
  const [gptOutputDivTextColor, setGptOutputDivTextColor] = useState('black');

  const [isMenuSidebarOpen, setIsMenuSidebarOpen] = useState(false);
  const onMenuSidebarClick = () => {
    //console.log("onMenuSidebarClick get clicked");
    setIsMenuSidebarOpen(!isMenuSidebarOpen);
  }
  const sidebarMenuRef = useRef(null);
  const handleOutsideOfSidebarMenuClick = (event) => {
    //console.log("handleOutsideOfSidebarMenuClick get clicked", event.target.id);
    if (sidebarMenuRef.current && !sidebarMenuRef.current.contains(event.target) && event.target.id !== "sideBarMenuId") {
      setIsMenuSidebarOpen(false);
    }
  };

  const premiumSectionRef = useRef(null);
  const onScrollToPremiumSection = () => {
    premiumSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const aboutSectionRef = useRef(null);
  const onScrollToAboutSection = () => {
    aboutSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  useEffect(() => { 
    updateUserSignedInStateAndEmail(); 

    {
      const $suggestionDivElement = document.getElementById('createContentSuggestionsDivId');
      const $suggestionListElement = document.getElementById('createContentSuggestionsListId');
      $suggestionListElement.innerHTML = '';
      Object.values(Utils.CREATE_CONTENT_ACTION_SUGGESTIONS).forEach(function(actionSuggestion) {
        var li = document.createElement('li');
        li.textContent = actionSuggestion;
        li.onclick = function() {
          setInputTextValue(actionSuggestion);
          Utils.hideHTMLElement($suggestionDivElement);
        };
        $suggestionListElement.appendChild(li);
      });
  
      document.addEventListener('click', function() {
        Utils.hideHTMLElement($suggestionDivElement);
      });
    }

    document.addEventListener('click', handleOutsideOfSidebarMenuClick);
    return () => {
      document.removeEventListener('click', handleOutsideOfSidebarMenuClick);
    };
  }, []);

  const [upgradeToPremiumLabelText, setUpgradeToPremiumLabelText] = useState(
    'You have credits remaining and the limit for GPT requests is 2 per minute. To enjoy unlimited usage and a significantly higher request limit, please Upgrade to Premium service through the UPGRADE button below.');
  const [upgradeToPremiumLabelTextColor, setUpgradeToPremiumLabelTextColor] = useState('gray');
  const updatePremiumLabelTextAndSubmitButton = (leftCredits, isSubscribed) => {
    if (isSubscribed) {
      setUpgradeToPremiumLabelText("");
    } else if (leftCredits < 5) {
      setUpgradeToPremiumLabelText("You have only " + leftCredits + " credits remaining. Upgrade to the Premium service now to get unlimited use without request limit!");
      setUpgradeToPremiumLabelTextColor("red");
    } else {
      setUpgradeToPremiumLabelText("You have " + leftCredits + " credits remaining and the limit for GPT requests is 2 per minute. To enjoy unlimited usage and a significantly higher request limit, we recommend upgrading to our Premium service through the UPGRADE button below.");
      setUpgradeToPremiumLabelTextColor("gray");
    }

    setSubmitButtonDisabled((!isSubscribed) && (leftCredits <= 0));
  }

  const updateUserSignedInStateAndEmail = async () => {
    const accessToken = await Utils.getUserAccesstoken();
    if (accessToken) {
      setIsUserSignedIn(true);

      const userInfo = await AuthUtils.currentUserInfo();
      if (userInfo?.attributes?.email) {
        setUserEmail(userInfo?.attributes?.email);
      }

      const subscriptionState = await Utils.getSubscriptionState();
      //console.log("subscription state", subscriptionState, Utils.isUserSubscribed(subscriptionState));
      setIsUserSubscribed(Utils.isUserSubscribed(subscriptionState));

      const leftCredits = Utils.getNumberOfLeftGPTCredits();
      updatePremiumLabelTextAndSubmitButton(leftCredits, Utils.isUserSubscribed(subscriptionState));
    }
  };

  const [isShowLoadingDimmer, setIsShowLoadingDimmer] = useState(false);

  const onClickSignup = async () => {
    const $gptSection = document.getElementById("gptSectionId");
    ReactUtils.showUserSignInOrOutPopup(null, $gptSection, true, updateUserSignedInStateAndEmail);
  }

  const onClickLogin = async () => {
    const $gptSection = document.getElementById("gptSectionId");
    ReactUtils.showUserSignInOrOutPopup(null, $gptSection, false, updateUserSignedInStateAndEmail);
  }

  const navigate = useNavigate();
  const onShowHistoryButtonClick = () => {
    if (isUserSubscribed) {
      navigate('/history');
    } else {
      ReactUtils.PurchasePremiumPopup(document.getElementById('workGPT_inputTextFieldId'), userEmail);
    }
  }

  const navigateToChromeExtension = () => {
    window.open(WORKGPT_CHROME_URL);
  };

  const navigateToURL= (url) => {
    window.open(url);
  };

  async function askGPT() {
    const user = await AuthUtils.currentAuthenticatedUser();
    if (user) {
      const session = await AuthUtils.currentSession();
      if (session?.getAccessToken()?.getJwtToken()) {
        try {
          const isExceedRequestLimitEveryMin = await Utils.getIsExcceedRequestLimitEveryMin();
          if (isExceedRequestLimitEveryMin) {
            setGptOutputValue("You have exceeded the request rate limit per minute with free version. Please retry after a couple of mins or Upgrade to our Premium Service package below with unlimited usage and much longer translation support!");
            setGptOutputDivTextColor('red');
            return;
          }

          const prompt = Utils.getPreSetPromptFromSettings() 
            + Utils.getGPTPromptFromInput(inputTextValue, lastActionSelected, additionalContext);
          const aiModel = Utils.getLocalStorageProperty(Utils.SETTINGS_GPT_MODEL_TO_USE) ?? Utils.DEFAULT_AI_MODEL;
          const gptCreativeness = 
            parseFloat(Utils.getLocalStorageProperty(Utils.SETTIGNS_GPT_CREATIVENESS) ?? Utils.DEFAULT_CREATIVENESS);

          const requestData = {
            prompt: prompt,
            aiModel: aiModel, 
            aiTemperature: gptCreativeness,
            accessToken: session?.getAccessToken()?.getJwtToken()
          };

          if (Utils.getLocalStorageProperty(Utils.SETTINGS_USER_GPT_KEY)) {
            requestData['userGPTKey'] = 'Bearer ' + Utils.getLocalStorageProperty(Utils.SETTINGS_USER_GPT_KEY);
          }
          
          setIsShowLoadingDimmer(true);
          const response = await Utils.awsClient.fetch(`${ASK_GPT_URL}/my-lambda/invocations`, 
            { body: JSON.stringify(requestData) });

          console.log("GPT response is", response);
          setIsShowLoadingDimmer(false);

          const gptResponse = await response.json();

          setGptOutputValue(gptResponse.gptResponse);
          setGptOutputDivTextColor('black');

          Utils.setSubscriptionState(gptResponse.subscriptionState);

          if (gptResponse.gptResponse) {
            Utils.setLastGPTRequestTimeStamp();
            Utils.increaseDailyRequest();

            if (!Utils.isUserSubscribed(gptResponse.subscriptionState)) {
              const leftCredits = Utils.decrementNumberOfLeftCredits();
              updatePremiumLabelTextAndSubmitButton(leftCredits, false);
            }

            await Utils.addToGPTHistory(gptResponse.gptResponse);
          }
        } catch(error) {
          console.error("Get GPT text failed", error.message);
          setIsShowLoadingDimmer(false);
          setGptOutputValue("Get GPT response failed: " + error.message);
          setGptOutputDivTextColor('red');
        }

      } else {
        console.error("onClickAskGPTutton(): get session failed for user", user);
        const $gptSection = document.getElementById("gptSectionId");
        ReactUtils.showUserSignInOrOutPopup(null, $gptSection, false, updateUserSignedInStateAndEmail);
      }
    } else {
      console.log("onClickAskGPTutton(): no authenticated user found");
      const $gptSection = document.getElementById("gptSectionId");
      ReactUtils.showUserSignInOrOutPopup(null, $gptSection, true, updateUserSignedInStateAndEmail);
    }
  }

  function clear() {
    setInputTextValue('');
    Utils.setLocalStorageProperty(Utils.SETTINGS_AUTO_SAVED_INPUT_CONTENT, '');
    setGptOutputValue('');
  }

  return (<div>
    <meta charSet="utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/water.css@2/out/water.css"></link>
    <title>WorkGPT: GPT for Workspace, GPT for Docs, GPT for Slides, GPT for Sheets, GPT for Gmail, GPT for Chrome</title>

    <style type="text/css">
      {Styles()}
    </style>

    {/* Your JavaScript script here */}
    {/* <script>
    </script> */}


  <div className={`ui vertical sidebar menu ${isMenuSidebarOpen ? 'visible' : ''}`} ref={sidebarMenuRef}>
    <a className="active item" href="https://www.aiworksuite.com">HOME</a>
    <a className="item" onClick={onScrollToAboutSection}>ABOUT</a>
    {isUserSignedIn && (!isUserSubscribed) && <a className="item" onClick={onScrollToPremiumSection}>PREMIUM PLAN</a>}
    <a className="item" href={CONTACT_US_URL}>HELP CENTER</a> 
    { !isUserSignedIn && <a className="item" onClick={onClickSignup}>START FREE</a> }
    { !isUserSignedIn && <a className="item" onClick={onClickLogin}>LOGIN</a> }
    <a className="item" onClick={onMenuSidebarClick} >CLOSE</a> 
  </div>

    <div className="ui container" >
      <div className="ui large secondary menu" style={{paddingBottom: '15px'}}>
        <a className="toc item">
          <i id = "sideBarMenuId" className="sidebar icon" onClick={onMenuSidebarClick}></i>
        </a>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
          <img src={appLogo} alt="logo" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
          <div style={{ fontSize: '19px', display: 'inline', fontWeight: '600', fontFamily: "Arial, sans-serif" }}>WorkGPT</div>
        </div>

        <a className="active item default-font-family" href="https://www.aiworksuite.com">Home</a>
        <a className="item default-font-family" onClick={onScrollToAboutSection}>About</a>
        {isUserSignedIn && (!isUserSubscribed) && <a className="item default-font-family" onClick={onScrollToPremiumSection}>Premium Plan</a>}

        <Dropdown text='Products' item className="default-font-family">
          <Dropdown.Menu>
            <Dropdown.Item text='WorkGPT: GPT for Google Workspace' onClick={() => navigateToURL(WORKGPT_FOR_WORKSPACE_URL)} />
            <Dropdown.Item text='WorkGPT for Chrome' onClick={navigateToChromeExtension} />
            <Dropdown.Item text='EmailAI' onClick={() => navigateToURL(EMAIL_AI_URL)} />
            <Dropdown.Item text='TranslateGPT' onClick={() => navigateToURL(TRANSLATE_GPT_FOR_WORKSPACE_URL)} />
          </Dropdown.Menu>
        </Dropdown>

        <a className="item default-font-family" href={CONTACT_US_URL}>Help Center</a>

        { !isUserSignedIn 
          && <a className="ui button" style={{ marginLeft: 'auto', backgroundColor: 'rgb(167, 52, 222)', color: 'white'}} onClick={onClickSignup}>Start Free</a> }
        { !isUserSignedIn 
          && <a className="ui button" style={{ marginLeft: '10px'}} onClick={onClickLogin}>Login</a> }
        { isUserSignedIn && <div style={{marginLeft: 'auto'}}>
              <button style={{ padding: '4px', marginTop: '5px', backgroundColor: 'white' }} data-tooltip="GPT History" 
                data-inverted="" data-variation="tiny" data-position="bottom center" onClick={onShowHistoryButtonClick}>
                <img src={historyImage} style={{ width: '18px' }} alt="" />
              </button>

            <Link to="/settings">
              <button id="settingsButtonId" style={{ padding: '4px', marginTop: '5px', backgroundColor: 'white' }} 
                data-tooltip="Settings" data-inverted="" data-variation="tiny" data-position="bottom center">
                <img id="settingsButtonImageId" src={settingsImage} style={{ width: '18px' }} alt="" />
              </button> 
            </Link>
          </div>}
      </div>
    </div>

    <div style={{backgroundColor: '#F6F8FF'}}>
      <div id="gptSectionId" className="ui container" style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <div className="ui secondary three pointing menu" id="workGPT_gptMainMenuId">
          <a className={`item blue ${activeMenuItem === EDIT_OR_READ_CONTENT_MENU_ID ? 'active' : ''}`} 
            id={EDIT_OR_READ_CONTENT_MENU_ID} onClick={(event) => handleMainMenuItemClick(event)}>Edit or Read Content</a>
          <a className={`item blue ${activeMenuItem === CREATE_CONTENT_MENU_ID ? 'active' : ''}`} 
            id={CREATE_CONTENT_MENU_ID} onClick={(event) => handleMainMenuItemClick(event)}>Create Content</a>
          <a className={`item blue ${activeMenuItem === ASK_GPT_MENU_ID ? 'active' : ''}`} 
            id={ASK_GPT_MENU_ID} onClick={(event) => handleMainMenuItemClick(event)}>Ask GPT</a>
        </div>

        <div id="inputSectionId">
          <textarea id="workGPT_inputTextFieldId" className='custom-textarea'
            style={{ height: '200px', marginTop: '6px' }}
            value={inputTextValue} onChange={handleInputTextChange} onClick={handleInputTextOnClick}
            placeholder={`${(activeMenuItem === CREATE_CONTENT_MENU_ID) ? INPUT_TEXT_PLACEHOLDER.CREATE_CONTENT 
                : ((activeMenuItem === ASK_GPT_MENU_ID) ? INPUT_TEXT_PLACEHOLDER.ASK_GPT 
                  : INPUT_TEXT_PLACEHOLDER.EDIT_READ_CONTENT)}`}></textarea>
          <div id="createContentSuggestionsDivId" className="suggestions-container" style={{ display: 'none', width: '280px' }}>
              <ul id="createContentSuggestionsListId"></ul>
          </div>
          <div style={{ marginBottom: '10px' }}></div>

          <div id="loadingDimmer" className={`ui inverted dimmer ${isShowLoadingDimmer ? 'active' : ''}`}>
              <div className="ui indeterminate text loader">Working</div></div>

          { (activeMenuItem === EDIT_OR_READ_CONTENT_MENU_ID) && <div id="workGPT_actionToTakeSelectionSectionId">
              <label style={{ color: 'gray', paddingRight: '5px' }}>2. Action to take</label>
              <select id="workGPT_actionSelectionId" style={{ display: 'inline' }} 
                value={lastActionSelected} onChange={handleActionToTakeChange}>
              {Object.entries(Utils.ACTION_TO_TAKE).map(([_, value]) => (<option key={value} value={value}>{value}</option>
          ))}</select></div> }

          { (activeMenuItem === EDIT_OR_READ_CONTENT_MENU_ID) && 
            <div id="workGPT_additionalContextSectionId" style={{ marginTop: '8px', marginBottom: '15px' }}>
              <div id="workGPT_additionalContextLabelDivId" style={{ color: 'gray', paddingRight: '5px', marginBottom: '5px' }}>
                {additionalContextHint}</div>
              <input type="text" id="workGPT_additionalContextInputId" style={{minWidth: '400px'}} 
                list="workGPT_additionalContextSuggestionsId" value={additionalContext} onChange={handleAdditionalContextChange}/>
              <datalist id="workGPT_additionalContextSuggestionsId">
                {additionalContextSuggestions.map((option, index) => (<option key={index} value={option} />))}</datalist>
            </div>}
        </div>

        <button id="askGPTButtonId" className="translation-button" onClick={askGPT} disabled={submitButtonDisabled}>
          <b>Submit and Ask GPT</b>
        </button>
        <button id="clearButtonId" className="" onClick={clear}>Clear</button>

        <div className="ui divider"></div>
        <div>
          <label style={{ color: 'gray', paddingBottom: '5px' }}>From AI GPT</label>
          <button id="copyResultTextButtonId" type="button" onClick={onCopyGPTOutputText}
              style={{ display: 'inline', padding: '2px', float: 'right', marginTop: '8px' }}
                data-tooltip="Copy" data-inverted="" data-variation="tiny" data-position="bottom center">
              <img src={copyImage} style={{ width: '18px' }} alt="copyButton" />
          </button>
        </div>
        <div id="gptOutputTextId" contentEditable="true" style={{ minHeight: '140px', whiteSpace: 'pre-wrap', color: gptOutputDivTextColor }} 
          suppressContentEditableWarning={true} >{gptOutputValue}</div>

        { isUserSignedIn && (!isUserSubscribed) && <div>
            <div className="ui divider"></div>
            <label id="upgradeToPremiumTextId" style={{ color: upgradeToPremiumLabelTextColor, marginTop: '20px', marginBottom: '20px'}}>
              {upgradeToPremiumLabelText}
            </label>
            <button id="upgradeButtonId" className="ui purple button large" 
                    onClick={ (event) => { ReactUtils.PurchasePremiumPopup(event.target, userEmail, activePurchasePlanShown, handlePurchasePlanShownItemClick);  } }>
                    <b>Upgrade to Premium Service</b> 
            </button>
        </div> }
        
        { isUserSignedIn && (!isUserSubscribed) && <div>
            <script async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6740757017179116"
              crossOrigin="anonymous"></script>
            <ins className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-6740757017179116"
              data-ad-slot="4356616512"
              data-ad-format="auto"
              data-full-width-responsive="true"
              onClick={() => {
                // Handle the click event here
                console.log('Adsbygoogle element clicked');
              }}
            ></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </div>}

      </div>
    </div>
    

    <div style={{backgroundColor: '#eef0fa'}}>
    <section id="about-section"></section>
    <div id="aboutSectionId" className="ui container" style={{paddingTop: '30px', paddingBottom: '60px'}} ref={aboutSectionRef}>
      <h1 className="ui horizontal header divider" style={{fontSize: '35px', fontFamily: "Arial, sans-serif"}}>
        <img src={appLogo} alt="logo" style={{ width: '40px', height: '40px', marginLeft: '-10px', marginRight: '15px', marginTop: '-5px' }} />
        <a style={{marginTop: '20px'}} href="#">WorkGPT</a>
      </h1>
      {/* <h3 id="sloganLabel" style={{ fontFamily: "Georgia, serif", paddingTop: '0px', fontSize: '25px', textAlign: 'center', color: '#10a37f'}}>Work Smart</h3> */}
      <h3 id="sloganLabel2" style={{ fontFamily: "Georgia, serif", fontSize: '25px', textAlign: 'center',  color: '#10a37f'}}>Boost Productivity and Efficiency with GPT</h3>
      <h3 id="sloganLabel3" style={{ fontFamily: "Georgia, serif", fontSize: '25px', textAlign: 'center', paddingBottom: '30px', color: 'rgb(0, 119, 255)'}}>Deliver Great Results and Achieve Excellence!</h3>
      <p className= "default-font" style={{marginBottom: '30px'}}>
      Experience the power of WorkGPT, your ultimate AI companion for Work!
      WorkGPT is the ultimate AI assistant and a game-changer designed specifically for your work needs. It harnesses the power of advanced AI technologies such as GPT-3.5, GPT-4, Google Bard PaLM2, and Meta AI LIama 2 to provide you with an unparalleled level of productivity and efficiency.
      <br></br><br></br>
      With WorkGPT, you can experience a 10x improvement in your writing and editing tasks. Whether you need help creating content (emails, posts, updates), understanding complex information, or enhancing your work in any way, WorkGPT has got you covered.
      Moreover, WorkGPT allows you to ask any question using a freeform prompt or create image directly.
      <br></br><br></br>
      You can seamlessly integrate WorkGPT into your workflow as it is available for use in Google Workspace applications such as Docs, Slides, and Sheets. It is also accessible through the web and as a convenient Chrome extension.
      <br></br><br></br>
      Rest assured, your data and privacy are of utmost importance to us. We have implemented robust security measures to guarantee the confidentiality and protection of your information.
      <br></br><br></br>
      So, why wait? Join the countless professionals who have already embraced the power of WorkGPT. Revolutionize your work, increase your efficiency, and unlock your true potential with the ultimate AI solution – WorkGPT.
      <br></br><br></br> 
      </p>
    

      <div id="productsSectionId" style={{paddingTop: '10px', paddingBottom: '60px'}} className="ui container">
        <h3 className="ui horizontal header" style={{color: 'rgb(0, 122, 255)', marginBottom: '30px'}}>
          WorkGPT Apps
        </h3>
        <div className="ui stackable equal height stackable grid">
              <div className="eight wide column">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/kjuXmwesVdM?si=k7k4u3ohYj6C0fae" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <div style={{marginTop: '20px'}}></div>
                <a href="https://www.producthunt.com/posts/workgpt?utm_source=badge-featured&utm_medium=badge&utm_source=badge-workgpt" target="_blank">
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=411708&theme=light"
                  alt="WorkGPT - Your ultimate GPT-powered AI assistant for Google Workspace | Product Hunt"
                  style={{ width: '250px', height: '54px' }}
                  width="250"
                  height="54"
                />
                </a>
              </div>
              <div className="eight wide column">
                <p className= 'custom-marginleft' style={{ fontSize: '19px', fontWeight: '600'}}>1. WorkGPT Addon for Google Workspace, Docs, Slides, Sheets, and Gmail. </p>
                <a href="https://workspace.google.com/marketplace/app/workgpt_gpt_chatgpt_for_workspace/368806015202?pann=b"
                  target="_blank" aria-label="Get it from the Google Workspace Marketplace">
                  <img alt="Google Workspace Marketplace badge"
                    alt-text="Get it from the Google Workspace Marketplace"
                    src="https://workspace.google.com/static/img/marketplace/en/gwmBadge.svg?"
                    style={{ height: '68px'}} className= 'custom-marginleft'
                  />
                </a>
                <div className= 'custom-marginleft' style={{fontSize: '19px', fontWeight: '600', paddingTop: '50px'}}>2. WorkGPT for Chrome</div>
                <div className= 'custom-marginleft' style={{marginTop: '20px'}}>
                  <button id="downloadChromeExtensionButtonId" className="ui button primary huge" onClick={navigateToChromeExtension}>
                      <b>Download for free</b>
                  </button>
                </div>
              </div>
        </div>
      </div>
      
      <h2 className="ui horizontal header" style={{color: 'rgb(0, 122, 255)', textAlign: 'center', marginBottom: '40px'}}>Features</h2>
      <div className="ui stackable equal height stackable grid" >
        <div className="eight wide column default-font" style={{paddingRight: '40px'}}>
          <b>General Assistance for your work and improving content:</b><br></br>
          <ul className="ui list">
              <li>Fix grammar and spelling </li>
              <li>Improve writing or rewrite with many tones supported (professional, friendly, formal, causal etc)! </li>
              <li>Elaborate</li>
              <li>Create tasks or to-do lists</li>
              <li>Offer suggestions to the content</li>
              <li>Provide different ways to say</li>
              <li>Expand and make longer, Shorten </li>
              <li>Reply to emails/questions/posts/comments with different tones</li>
              <li>Bulletize, format</li>
              <li>Provide suggestions or different ways to say</li>
              <li>Any other freeform prompt and command you want for GPT.</li>
          </ul>
        </div>
        <div className="eight wide column">
          <img style = {{marginTop: '40px'}} src={editContentDemo} alt="EditContent" />
        </div>
      </div>

      <div className="ui stackable equal height stackable grid" >
          <div className="eight wide column default-font"  style={{paddingRight: '40px'}}>
            <br></br>
            <b>Creating content: </b><br></br>
            <ul className="ui list">
              <li>Write emails</li>
              <li>Make notes</li>
              <li>Write SEO-optimized content or article</li>
              <li>Generate post for Linkedin or Facebook</li>
              <li>Create script for Youtube or Tiktok videos</li>
              <li>Generate images</li>
              <li>Compose status update or summary</li>
              <li>Create descriptions</li>
              <li>Create Slides </li>
              <li>Generate ideas</li>
              <li>Write for anything!</li>
            </ul>
          </div>
          <div className="eight wide column">
            <img style = {{marginTop: '60px'}} src={createContentDemo} alt="CreateContent" />
          </div>
      </div>
      <div className="default-font" style={{paddingTop: '40px'}}>It can also translate to different language, summarize content, help you understand, explain content, provide synonyms, 
        dictionary to help you understand and many more!</div>


      <h2 className="ui horizontal header" style={{color: 'rgb(0, 122, 255)', textAlign: 'center', marginTop: '100px', marginBottom: '40px'}}>
        How to use WorkGPT in Google Docs</h2>
      <div style={{ marginLeft: "auto", marginRight: "auto", width: '70%' }}>
        {/* see here for the responsive video frame, https://www.w3schools.com/howto/howto_css_responsive_iframes.asp */}
        <div className="video-container">
          <iframe src="https://www.youtube.com/embed/NiMm20bDuz0?si=xTATOqVu9sarHxyO" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
      </div>

      <h2 className="ui horizontal header" style={{color: 'rgb(0, 122, 255)', textAlign: 'center', marginTop: '100px', marginBottom: '40px'}}>
        How to use WorkGPT in Gmail</h2>
      <div style={{ marginLeft: "auto", marginRight: "auto", width: '70%' }}>
        <div className="video-container">
          <iframe src="https://www.youtube.com/embed/FXnPhISKzNk?si=TH6KAOxCkEnw99tI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
      </div>

    </div>
    </div>

    <div>
      <script async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6740757017179116"
        crossOrigin="anonymous"></script>
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-6740757017179116"
        data-ad-slot="4356616512"
        data-ad-format="auto"
        data-full-width-responsive="true"
        onClick={() => {
          // Handle the click event here
          console.log('Adsbygoogle element clicked');
        }}
      ></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </div>
    
    <section id="premium-plan-section"></section>
    { isUserSignedIn && (!isUserSubscribed)  && <div id="premiumServiceSectionId" style={{backgroundColor: '#F8F8F8', paddingTop: '30px', paddingBottom: '30px'}} ref={premiumSectionRef}>
      <div className="ui container">
        <h1 className="ui horizontal header divider">
          <a href="#">{(activePurchasePlanShown === Utils.PURCHASE_PLAN.ENTERPRISE) ? "Enterprise Service" : 'Premium Service'}</a>
        </h1>
        <div id="NotUpgradedToPremiumService">
          <div className="centered">
            <div className="ui two item menu" id="workGPT_premiumPlanMenuId" style={{marginTop: '50px', width: '60%'}}>
              <a className={`item blue ${activePurchasePlanShown === Utils.PURCHASE_PLAN.PREMIUM ? 'active' : ''}`} 
                id = {Utils.PURCHASE_PLAN.PREMIUM} onClick={(event) => handlePurchasePlanShownItemClick(event)}>Premium Service</a>
              <a className={`item blue ${activePurchasePlanShown === Utils.PURCHASE_PLAN.ENTERPRISE ? 'active' : ''}`} 
                id = {Utils.PURCHASE_PLAN.ENTERPRISE} onClick={(event) => handlePurchasePlanShownItemClick(event)}>Enterprise Service</a>
            </div>
          </div>
          
          {ReactUtils.PurchaseUIElements(activePurchasePlanShown, userEmail)}
          <label style={{ paddingRight: '5px', color: 'gray', fontSize: '17px' }}>
            Have more questions, or want to purchase a license for a group of people or the whole team/company?
            Please contact us at <a href={CONTACT_US_URL}>aiworksuite.com/contact-us</a>.
            We are happy to answer your questions or discuss related offers.
          </label>
        </div>
      </div>
    </div> }

    <Footer></Footer>

  </div>
  );
}

const Styles = () => (
  <style>
    {`
      body {
        max-width: 100%; !important;
        padding: 0px;
      }

      .video-container {
        position: relative;
        width: 100%;
        height: '0';
        padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
      }

      .default-font {
        font-size: 19px;
        font-family: Helvetica, arial, sans-serif; 
      }

      .default-font-family {
        font-family: Helvetica, arial, sans-serif; 
      }
      
      /* Then style the iframe to fit in the container div with full height and width */
      .video-container iframe {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .centered {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .translation-button {
        background-color: rgb(0, 119, 255);
        color: white;
        min-width: 150px;
        height: 40px;
      }

      .hidden.menu {
        display: none;
      }

      .masthead.segment {
        min-height: 700px;
        padding: 1em 0em;
      }
      .masthead .logo.item img {
        margin-right: 1em;
      }
      .masthead .ui.menu .ui.button {
        margin-left: 0.5em;
      }
      .masthead h1.ui.header {
        margin-top: 3em;
        margin-bottom: 0em;
        font-size: 4em;
        font-weight: normal;
      }
      .masthead h2 {
        font-size: 1.7em;
        font-weight: normal;
      }

      .ui.vertical.stripe {
        padding: 8em 0em;
      }
      .ui.vertical.stripe h3 {
        font-size: 2em;
      }
      .ui.vertical.stripe .button + h3,
      .ui.vertical.stripe p + h3 {
        margin-top: 3em;
      }
      .ui.vertical.stripe .floated.image {
        clear: both;
      }
      .ui.vertical.stripe p {
        font-size: 1.33em;
      }
      .ui.vertical.stripe .horizontal.divider {
        margin: 3em 0em;
      }

      .quote.stripe.segment {
        padding: 0em;
      }
      .quote.stripe.segment .grid .column {
        padding-top: 5em;
        padding-bottom: 5em;
      }

      .footer.segment {
        padding: 5em 0em;
      }

      .secondary.pointing.menu .toc.item {
        display: none;
      }

      .custom-textarea {
        min-width: 450px !important; /* Adjust the value as needed */
      }

      .custom-marginleft {
        margin-left: 60px; !important;
      }

      .suggestions-container {
        border: 1px solid #ccc;
        padding: 5px;
        position: absolute;
        background-color: white;
        width: 300px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        z-index: 1000;
      }
      
      .suggestions-container ul {
          list-style: none;
          padding: 0;
          margin: 0;
      }
      
      .suggestions-container li {
          padding: 5px;
          cursor: pointer;
      }
      
      .suggestions-container li:hover {
          background-color: #f0f0f0;
      }

      @media only screen and (max-width: 700px) {
        .ui.fixed.menu {
          display: none !important;
        }
        .secondary.large.menu .item,
        .secondary.large.menu .button,
        .secondary.large.menu .menu {
          display: none;
        }
        .secondary.large.menu .toc.item {
          display: block;
        }
        .masthead.segment {
          min-height: 350px;
        }
        .masthead h1.ui.header {
          font-size: 2em;
          margin-top: 1.5em;
        }
        .masthead h2 {
          margin-top: 0.5em;
          font-size: 1.5em;
        }

        .custom-textarea {
          /* Default height for larger screens */
          min-width: 100% !important;
        }

        .custom-marginleft {
          margin-left: 0px;
        }
      }
    `}
  </style>
);


/* 
1. Create Content suggestions and more prompts (Done)
2. Sign up with Google (Done)
3. Todo: add create images functionality 

  } else if (request[utils.MESSAGE_TYPE_KEY] == utils.MESSAGE_TYPE.GPT_CREATE_IMAGE) {
    (async () => {
      try {
        if (request[utils.SEND_MESSAGE_GPT_PROMPT]) {  
          const requestData = {
            prompt: request[utils.SEND_MESSAGE_GPT_PROMPT],
            type: GPT_REQUEST_TYPE.CREATE_IMAGE,
            imageSize: request[utils.SEND_MESSAGE_IMAGE_SIZE],
            accessToken: request[utils.SEND_MESSAGE_ACCESS_TOKEN],
            surface: utils.SURFACE_TYPE.CHROME_EXT,
          };

          const response = await awsClient.fetch(`${ASK_GPT_URL}/my-lambda/invocations`, 
            { body: JSON.stringify(requestData) });
          const gptResponse = await response.json();

          console.log("gpt create image response is", gptResponse);
          sendResponse({[utils.SEND_MESSAGE_RESPONSE]: gptResponse.imageURL});
        } else {
          console.error("gpt create image prompt is null", JSON.stringify(request));
        }
      } catch(error) {
        console.error("Create image prompt failed");
        sendResponse({[utils.SEND_MESSAGE_ERROR_RESPONSE]: "Create image failed: " + error.message});
      }
    })();

4. Show GPT-4


*/
