/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_B6oBAnlHi",
    "aws_user_pools_web_client_id": "36ri0pf7iahc77nbhqn8fvksuj",
    "oauth": {
        "domain": "workgpt.auth.us-west-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "chrome-extension://ahogmlahnmglmmhgdakigaijmjdnjmpo/options/options.html,chrome-extension://aijkbfcgfacnbgaladlemlbonnddphdf/options/options.html,http://localhost:3000/,https://ahogmlahnmglmmhgdakigaijmjdnjmpo.chromiumapp.org/,https://www.workgpt.aiworksuite.com/",
        "redirectSignOut": "chrome-extension://ahogmlahnmglmmhgdakigaijmjdnjmpo/options/options.html,chrome-extension://aijkbfcgfacnbgaladlemlbonnddphdf/options/options.html,http://localhost:3000/,https://ahogmlahnmglmmhgdakigaijmjdnjmpo.chromiumapp.org/,https://www.workgpt.aiworksuite.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "Auth": {
        "identityPoolId": "us-west-2:ae9655a5-9585-43ee-ad56-4d23316e842f",
        "region": "us-west-2"
    },
    "Analytics": {
        "AWSPinpoint": {
            "appId": "23b3eedf73e44212a60fe40d9d34380a",
            "region": "us-west-2",
            "mandatorySignIn": false
        }
    }
};


export default awsmobile;
