import React, { useEffect } from 'react';
//import { Amplify } from 'aws-amplify';

import * as AuthUtils from '../utils/AuthUtils.js';
import * as ReactUtils from "../utils/ReactUtils.js";
import * as Utils from '../utils/Utils.js';
import "semantic-ui-css/semantic.min.css"
import WorkGPTImage from '../images/workgpt_128.png';

function $languageSelectionElement() { return document.getElementById("languageSelectionId"); }
function $gptModelSelectionElement() { return document.getElementById("gptModelSelectionId"); }
function $gptCreativenessSelectionElement() { return document.getElementById("creativenessSelectionId"); }
function $gptRoleSelectionElement() { return document.getElementById("roleOfGPTId"); }
function $customKnowledgeTextAreaElement() { return document.getElementById("workGPT_customKnowledgeId"); }
function $gptKeyInputTextElement() { return document.getElementById("workGPT_gptKeyTextFieldId"); }

function $needSignUpOrLoginSectionElement() { return document.getElementById('needSignUpOrLoginSectionId'); }
function $accountDetailsSectionElement() { return document.getElementById('accountDetailsSectionId'); }
function $emailAddressElement() { return document.getElementById('emailAddressId'); }
function $signOutButtonElement() { return document.getElementById('signOutButtonId'); }
function $startSignUpOrLoginButtonElement() { return document.getElementById('startSignUpOrLoginButtonId'); }

function $upgradeToPremiumServiceButtonElement() { return document.getElementById('upgradeToPremiumSerivceButtonId'); }
function $manageYourSubscriptionButtonElement() { return document.getElementById('manageSubscriptionButtonId'); }
function $subcriptionSectionElement() { return document.getElementById('subscriptionSectionId'); }

const saveOptions = () => {
  Utils.setLocalStorageProperty(Utils.SETTINGS_GPT_MODEL_TO_USE, $gptModelSelectionElement().value);
  Utils.setLocalStorageProperty(Utils.SETTINGS_GPT_RESPOND_LANGUAGE, $languageSelectionElement().value);
  Utils.setLocalStorageProperty(Utils.SETTIGNS_GPT_CREATIVENESS, $gptCreativenessSelectionElement().value);
  Utils.setLocalStorageProperty(Utils.SETTINGS_GPT_ROLE_AS, $gptRoleSelectionElement().value);
  Utils.setLocalStorageProperty(Utils.SETTINGS_GPT_CUSTOM_KNOWLEDGE, $customKnowledgeTextAreaElement().value);

  const status = document.getElementById('status');
  status.textContent = 'Settings saved.';
  setTimeout(() => {status.textContent = '';}, 750);
};

const saveGPTKey = () => {
  const gptKeyInput = $gptKeyInputTextElement().value;
  Utils.setLocalStorageProperty(Utils.SETTINGS_USER_GPT_KEY, gptKeyInput);

  const status = document.getElementById('gptKeyStatusId');
  status.textContent = 'Key Saved';
  setTimeout(() => {status.textContent = '';}, 750);
}

const removeGPTKey = () => {
  Utils.setLocalStorageProperty(Utils.SETTINGS_USER_GPT_KEY, "");
  $gptKeyInputTextElement().value = "";
}

// Restores select box and checkbox state using the preferences
const initOptionsUI = async () => {
  const aiModelToUse = Utils.getLocalStorageProperty(Utils.SETTINGS_GPT_MODEL_TO_USE) ?? Utils.DEFAULT_AI_MODEL;
  Utils.initGPTModelElement(aiModelToUse, $gptModelSelectionElement());

  const gptRespondLanguage = 
    Utils.getLocalStorageProperty(Utils.SETTINGS_GPT_RESPOND_LANGUAGE) ?? Utils.DEFAULT_RESPOND_LANGUAGE;
  Utils.initTranslateLanguageElement(gptRespondLanguage, $languageSelectionElement());

  const gptCreativeness = 
    parseFloat(Utils.getLocalStorageProperty(Utils.SETTIGNS_GPT_CREATIVENESS) ?? Utils.DEFAULT_CREATIVENESS);
  Utils.initGPTCreativnessElement(gptCreativeness, $gptCreativenessSelectionElement());

  const gptRole = Utils.getLocalStorageProperty(Utils.SETTINGS_GPT_ROLE_AS) ?? Utils.DEFAULT_GPT_ROLE;
  Utils.initGPTRoleAs(gptRole, $gptRoleSelectionElement());

  $customKnowledgeTextAreaElement().value = 
    Utils.getLocalStorageProperty(Utils.SETTINGS_GPT_CUSTOM_KNOWLEDGE) ?? "";

  const userGPTKey = Utils.getLocalStorageProperty(Utils.SETTINGS_USER_GPT_KEY);
  $gptKeyInputTextElement().value = userGPTKey;

  await loadAccountDetailsAndSubscriptionSection();
};

async function loadAccountDetailsAndSubscriptionSection() {
  //console.log("loadAccountDetailsAndSubscriptionSection");
  //console.trace("loadAccountDetailsAndSubscriptionSection call stack");
  const user = await AuthUtils.currentAuthenticatedUser();
  var subscriptionState = Utils.SubscriptionState.NONE;

  Utils.hideHTMLElement($manageYourSubscriptionButtonElement());
  Utils.hideHTMLElement($upgradeToPremiumServiceButtonElement());

  if (user) {
    Utils.hideHTMLElement($needSignUpOrLoginSectionElement());
    Utils.showHTMLElement($accountDetailsSectionElement());
    Utils.showHTMLElement($subcriptionSectionElement());

    const userInfo = await AuthUtils.currentUserInfo();
    if ($emailAddressElement()) {
      $emailAddressElement().textContent = userInfo?.attributes?.email;
    }
    $signOutButtonElement()?.addEventListener('click', 
      () => { ReactUtils.showUserSignInOrOutPopup(user, $accountDetailsSectionElement(), false, initOptionsUI); });

    subscriptionState = await Utils.getSubscriptionState();
    //console.log("subscriptionState is ", subscriptionState, subscriptionState == Utils.SubscriptionState.NONE);
    if (subscriptionState === Utils.SubscriptionState.NONE) {
      Utils.showHTMLElement($upgradeToPremiumServiceButtonElement());
      $upgradeToPremiumServiceButtonElement().addEventListener('click', 
        () => ReactUtils.PurchasePremiumPopup($upgradeToPremiumServiceButtonElement(), userInfo?.attributes?.email));
      ReactUtils.PurchasePremiumPopup($upgradeToPremiumServiceButtonElement(), userInfo?.attributes?.email);
    } else {
      Utils.showHTMLElement($manageYourSubscriptionButtonElement());
      $manageYourSubscriptionButtonElement().addEventListener('click', onOpenManageSubscriptionLink);
    }
  } else {
    Utils.hideHTMLElement($accountDetailsSectionElement());
    Utils.showHTMLElement($needSignUpOrLoginSectionElement());
    Utils.hideHTMLElement($subcriptionSectionElement());
    
    $startSignUpOrLoginButtonElement().addEventListener('click', 
      () => { ReactUtils.showUserSignInOrOutPopup(user, $needSignUpOrLoginSectionElement(), false, loadAccountDetailsAndSubscriptionSection); });
    ReactUtils.showUserSignInOrOutPopup(user, $needSignUpOrLoginSectionElement(), true, loadAccountDetailsAndSubscriptionSection);
  }
}

const onOpenManageSubscriptionLink = () => {
  window.location.href = 'https://billing.stripe.com/p/login/6oE4hl3LN1bk0W4000';
}

const SettingsPage = () => {
  useEffect(() => {
    console.log("load useeffect");
    initOptionsUI();
    document.getElementById('saveSettingsButtonId').addEventListener('click', saveOptions);
    document.getElementById('saveGPTKeyButtonId').addEventListener('click', saveGPTKey);
    document.getElementById('removeKeyButtonId').addEventListener('click', removeGPTKey);

  }, []);

  return (
    <div>
      <meta charSet="utf-8" />
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/water.css@2/out/water.css"></link>
      <title>WorkGPT</title>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={WorkGPTImage} alt="logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
        <p style={{ fontSize: '17px' }}>WorkGPT</p>
      </div>

      <div className="ui divider"></div>
      <h3 style={{ margin: '30px 0 20px 0' }}>GPT Settings</h3>
      <div>
        <label style={{ paddingRight: '10px' }}>Language GPT respond</label>
        <select id="languageSelectionId" style={{ display: 'inline' }}>
        </select>
      </div>
      <div style={{marginTop: '4px'}}>
        <label style={{ paddingRight: '10px' }}>AI Model to use</label>
        <select id="gptModelSelectionId" style={{ display: 'inline' }}>
        </select>
      </div>
      <div style={{marginTop: '4px'}}>
        <label style={{ paddingRight: '10px' }}>How creative GPT is</label>
        <select id="creativenessSelectionId" style={{ display: 'inline' }}>
        </select>
      </div>
      <div style={{marginTop: '4px'}}>
        <label style={{ paddingRight: '10px' }}>The role GPT act as</label>
        <select id="roleOfGPTId" style={{ display: 'inline' }}>
        </select>
      </div>
      <div style={{marginTop: '8px'}}>
        <label style={{ paddingRight: '10px' }}><b>Custom knowledge</b></label>
        <textarea id="workGPT_customKnowledgeId" className='custom-textarea'
            style={{ height: '100px', marginTop: '6px', marginBottom: '10px' }}
            placeholder={`Enter your custom knowledge or context you want GPT to know or use (max 200 words)`}></textarea>
      </div>
      <br />

      <button id="saveSettingsButtonId" className="ui primary button">Save</button>
      <div id="status"></div>

      <div className="ui divider"></div>
      <h3 style={{ margin: '30px 0 20px 0' }}>Use your own ChatGPT API Key</h3>
      <label>(optional in case you want to use your own key for particular reasons. We offer you to use the GPT service and product without your API key)</label>
      <input id="workGPT_gptKeyTextFieldId" type="text" style={{ width: '400px' }} 
        placeholder="Enter or use you GPT Key" ></input>
      <div style={{ marginBottom: '20px' }}  ></div>
      <button id="saveGPTKeyButtonId" className="ui button" style={{ marginRight: '15px' }} >Save GPT Key</button>
      <button id="removeKeyButtonId" className="ui basic button">Remove Key</button>
      <div id="gptKeyStatusId"></div>
      <div className="ui divider"></div>
      <h3 style={{ margin: '10px 0' }}>Account</h3>
      <div id="needSignUpOrLoginSectionId">
        <div style={{ fontSize: '15px', margin: '0 0 15px' }}>You haven't signed in yet, sign in and start translating.</div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button id="startSignUpOrLoginButtonId" style={{ margin: '15px 0px', width: '60%' }} className="ui primary button"><b>Sign up or Log in</b></button>
        </div>
      </div>
      <div id="accountDetailsSectionId">
        <label style={{ paddingRight: '10px' }}><b>Email:</b></label>
        <label id="emailAddressId" style={{ paddingRight: '10px' }}></label>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button id="signOutButtonId" style={{ margin: '15px 0px', width: '60%' }} className="ui basic button">Sign out</button>
        </div>
      </div>

      <div id="subscriptionSectionId">
        <div className="ui divider"></div>
        <h3 style={{ margin: '10px 0' }}>Subscription</h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button id="upgradeToPremiumSerivceButtonId" style={{ margin: '15px 0px', width: '60%' }} className="ui primary button"><b>Upgrade to Premium Service</b></button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button id="manageSubscriptionButtonId" style={{ margin: '15px 0px', width: '60%' }} className="ui basic button">Manage your subscription</button>
        </div>
      </div>

    </div>
  );
}

export default SettingsPage;