import React from 'react';
import appLogo from "../images/workgpt_128.png";

const Footer = () => {
  return (
    <div id="footerSectionId" className="ui vertical footer segment">
    <div className="ui container">
      <div className="ui stackable equal height stackable grid">
        <div className="four wide column">
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <img src={appLogo} alt="logo" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
            <div style={{ fontSize: '20px', display: 'inline', fontWeight: '500' }}>WorkGPT</div>
          </div>
        </div>
        <div className="four wide column">
          <h3 className="ui header">Resources</h3>
          <div className="ui link list">
            <a href="https://aiworksuite.com/contact-us" className="item">Contact Us</a>
            <a href="/privacyPolicy" className="item">Privacy Policy</a>
            <a href="/termsOfService" className="item">Terms of Service</a>
          </div>
        </div>
        <div className="six wide column">
          <h3 className="ui header">Download Free</h3>
          <div className="ui link list">
            <a href="https://workspace.google.com/marketplace/app/workgpt_gpt_chatgpt_for_workspace/368806015202" className="item">WorkGPT for Google Workspace</a>
            <a href="https://chrome.google.com/webstore/detail/workgpt-gpt-for-work/aijkbfcgfacnbgaladlemlbonnddphdf" className="item">WorkGPT Chrome extension</a>
          </div>
        </div>
        <div className="four wide column">
        </div>
        {/* <div className="seven wide column">
          <h4 className="ui header">Footer Header</h4>
          <p>Extra space for a call to action inside the footer that could help re-engage users.</p>
        </div> */}
      </div>
    </div>
    <div className="centered" style={{marginTop: '40px'}}>
      <div>Copyright © 2023 ZQ Labs, Inc. - All Rights Reserved.</div>
    </div>
  </div>
  )

};

export default Footer;