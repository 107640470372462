import React, { useState } from 'react';
import { createRoot } from 'react-dom/client'
import { Authenticator, View, Button } from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import '@aws-amplify/ui-react/styles.css';
import { Hub } from 'aws-amplify';
import * as AuthUtils from './AuthUtils.js';
import * as Utils from './Utils.js';

//import { Analytics } from "aws-amplify";

const MAX_zIndex = "2147483647"


export function showUserSignInOrOutPopup(user, $elementWhichAuthAppendTo, isSignUp, callbackAfterDone = () => {}) {
  const signInRootDiv = document.createElement('div');
  signInRootDiv.id = 'signInRoot';
  const signInRoot = createRoot(signInRootDiv);
  $elementWhichAuthAppendTo.appendChild(signInRootDiv);

  const closeModal = () => {
    //console.log("close Modal")
    signInRoot.unmount();
    if ($elementWhichAuthAppendTo.contains(signInRootDiv)) {
      $elementWhichAuthAppendTo.removeChild(signInRootDiv);
    }
  };

  Hub.listen('auth', async (data) => {
    // eslint-disable-next-line default-case
    switch (data.payload.event) {
      case 'signIn':
          console.log('user signed in', data.payload.data?.attributes?.email);
          // Analytics.record({
          //   name: 'UserSignIn',
          //   attributes: { userEmail: data.payload.data?.attributes?.email, client: 'workGPT_web' }
          // });
          closeModal();
          //$elementWhichAuthAppendTo.style.display = "none";
          callbackAfterDone();
          break;
      case 'signUp':
        console.log('user signed up', data.payload.data?.attributes?.email);
        // Analytics.record({
        //   name: 'UserSignUp',
        //   attributes: { userEmail: data.payload.data?.attributes?.email, client: 'workGPT_web' }
        // });
        break;
      case 'signOut':
          console.log('user signed out');
          closeModal();
          await Utils.clearStoragePropertyAfterSignout();
          callbackAfterDone();
          break;
      case 'signIn_failure':
          console.log('user sign in failed');
          break;
      case 'configured':
          console.log('the Auth module is configured');
    }
  });

  //const amplify_css = chrome.runtime.getURL("utils/amplify_ui.css");
  if (user) {
    signInRoot.render(<Modal
      isOpen={true}
      contentLabel=""
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: MAX_zIndex - 1,
        },
        content: {
          width: '80%',
          height: "70%",
          maxWidth: '400px',
          maxHeight: '240px',
          margin: 'auto',
          padding: '0px',
          zIndex: MAX_zIndex
        },
      }}
    >
      {/* <link rel="stylesheet" type="text/css" /> */}
      <Authenticator>
       {({signOut}) => (
            <main>
              <h4 style={{ padding: '20px 0px 15px 0', textAlign: 'center' }}>Do you want to sign out?</h4>
              <div style={{ padding: '0px 20px 20px 20px'}} >Sign out will clear all your local preferences, settings and GPT history to protect your data.
                 Please make sure to save these information if you want to use them later.</div>
              <div style= {{display: 'flex', justifyContent: 'center'}} >
                <button className="ui button" onClick={() => { signOut(); }}>Sign out</button>
                {/* Todo: closeModal() here doesn't work for the first time */}
                <button className="ui primary button" onClick={() => { closeModal(); }}>Cancel</button>
              </div>
            </main>
        )}
      </Authenticator>, 
    </Modal>);
  } else {
    //console.log("show user sign up and sign in popup");
    const components = {    
      SignIn: {
        Footer() {    
          return (
            <View textAlign="center">
              <Button fontWeight="normal" marginBottom="10px" onClick={closeModal} size="small" variation="link">Close</Button>
              <br></br>
            </View>
          );
        },
      },
      SignUp: {
        Footer() {    
          return (
            <View textAlign="center">
              <Button fontWeight="normal" marginBottom="10px" onClick={closeModal} size="small" variation="link">Close</Button>
            </View>
          );
        },
      },
    }

    signInRoot.render(<Modal
        isOpen={true}
        contentLabel=""
        ariaHideApp={false}
        style={{
          overlay: {
            zIndex: MAX_zIndex - 1, 
          },
          content: {
            width: '80%',
            height: "70%",
            maxWidth: '800px',
            maxHeight: '500px',
            margin: 'auto',
            padding: '0px',
            border: 'none',
            background: 'none', 
            zIndex: MAX_zIndex
          },
        }}
      >
      <Authenticator components={components} initialState={isSignUp ? "signUp" : "signIn"} socialProviders={['google']}>
      </Authenticator>
    </Modal>);
  }
}

export const onOpenMonthlyPremiumSubscriptionLink = (userEmail) => {
  window.open(Utils.MONTHLY_PREMIUM_SUBSCRIPTION_PAYMENT_LINK + encodeURIComponent(userEmail));
}

export const onOpenYearlyPremiumSubscriptionLink = (userEmail) => {
  window.open(Utils.YEARLY_PREMIUM_SUBSCRIPTION_PAYMENT_LINK + encodeURIComponent(userEmail));
}

export const onOpenMonthlyEnterpriseSubscriptionLink = (userEmail) => {
  window.open(Utils.MONTHLY_ENTERPRISE_SUBSCRIPTION_PAYMENT_LINK + encodeURIComponent(userEmail));
}

export const onOpenYearlyEnterpriseSubscriptionLink = (userEmail) => {
  window.open(Utils.YEARLY_ENTERPRISE_SUBSCRIPTION_PAYMENT_LINK + encodeURIComponent(userEmail));
}

export const updateSubscriptionState = async (target) => {
  const session = await AuthUtils.currentSession();
  if (session?.getAccessToken()?.getJwtToken()) {
    await Utils.fetchSubscriptionStateFromBackend(session?.getAccessToken()?.getJwtToken());
  } else {
    showUserSignInOrOutPopup(null, target, false);
  }
}

export function PurchasePremiumPopup($upgradeToPurchaseButtonElement, userEmail) {
  const purchasePremiumDiv = document.createElement('div');
  purchasePremiumDiv.id = 'purchasePremiumRoot';
  var purchasePremiumRoot = createRoot(purchasePremiumDiv);
  $upgradeToPurchaseButtonElement.appendChild(purchasePremiumDiv);

  const goToEnterpriseOffers = () => {
    getPurchaseUIElementsForSinglePlan(Utils.PURCHASE_PLAN.ENTERPRISE, purchasePremiumRoot, userEmail, 
      closeModal, null, goToPremiumOffers);
  }

  const goToPremiumOffers = () => {
    getPurchaseUIElementsForSinglePlan(Utils.PURCHASE_PLAN.PREMIUM, purchasePremiumRoot, userEmail, 
      closeModal, goToEnterpriseOffers, null);
  }

  const closeModal = () => {
    //console.log("close Modal")
    purchasePremiumRoot.unmount();
    $upgradeToPurchaseButtonElement.removeChild(purchasePremiumDiv);
  }

  getPurchaseUIElementsForSinglePlan(Utils.PURCHASE_PLAN.PREMIUM, purchasePremiumRoot, userEmail,
      closeModal, goToEnterpriseOffers, goToPremiumOffers);
}


function getPurchaseUIElementsForSinglePlan(purchasePlan, purchasePremiumRoot, userEmail, 
    closeModal, goToEnterpriseOffers, goToPremiumOffers) {
  const isPremiumService = (purchasePlan === Utils.PURCHASE_PLAN.PREMIUM);
  
  // console.log("getPurchaseUIElementsForSinglePlan", purchasePlan, goToPremiumOffers);
  purchasePremiumRoot.render(<Modal
    isOpen={true}
    contentLabel=""
    ariaHideApp={false}
    style={{
      overlay: {
        zIndex: MAX_zIndex - 1,
        inset: '0px'
      },
      content: {
        width: '105%',
        height: "100%",
        maxWidth: '600px',
        maxHeight: '830px',
        margin: 'auto',
        padding: '10px 30px',
        inset: '0px',
        zIndex: MAX_zIndex
      },
    }}
  >
     <div>
      <link async rel="stylesheet" href="https://cdn.jsdelivr.net/npm/semantic-ui@2/dist/semantic.min.css"/>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/water.css@2/out/water.css"></link>
      <h4 style={{ color: '#4285F4'}}>
        { isPremiumService ? <b>Upgrade to Premium Service and Support</b>  : 
          <b>Upgrade to Enterprise Service and Support</b> }
      </h4>

      <div className="ui divider"></div>

      <label style={{ padding: '5px' }}>
        1. <b>No limitation</b> for credit usage of AI WorkGPT requests. <br />
        2. { isPremiumService ? <b>400 GPT requests every day</b> : <b>2500 GPT requests every day</b>} for you to use! <br />
        3. <b>Best AI models</b> to utilize, ChatGPT, Google Bard PaLM 2, Meta LIama 2 etc. <br />
        4. <b>Long GPT input</b> supported with 25000 characters each time. <br />
        5. <b>Many great features</b> to help you improve and create content and images, custom GPT, history etc<br />
        6. <b>Faster response</b> with dedicated GPT server. <br />
        7. <b>Priority support</b> for your issues and requests. <br />
        8. <b>Multiple surfaces supported</b> to use anytime and anywhere, WebApp, Google Workspace addon, Chrome Extension. <br /><br />
        { isPremiumService ? 'Premium' : 'Enterprise' } offers available:
      </label>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          id="yearlyPremiumButtonId"
          style={{ margin: '15px 0px 10px', width: '90%', maxWidth: '500px', minHeight: '45px', 
          backgroundColor: 'rgb(0, 119, 255)', color: 'white' }}
          onClick={() => isPremiumService ? onOpenYearlyPremiumSubscriptionLink(userEmail) : onOpenYearlyEnterpriseSubscriptionLink(userEmail)}
        >
           {isPremiumService ? <b>$4.16 / month with Premium Year for $49.99 (50% off)</b> : 
            <b>$14.99 / month with Enterprise Year for $179.99 (20% off)</b> }
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button id="monthlyPremiumButtonId"
          style={{ margin: '0px 0px 10px', width: '90%', maxWidth: '500px', minHeight: '40px', 
          backgroundColor: '#06ADEF', color: 'white' }}
          onClick={() => isPremiumService ? onOpenMonthlyPremiumSubscriptionLink(userEmail) : onOpenMonthlyEnterpriseSubscriptionLink(userEmail)}
        >
          {isPremiumService ? <b>Premium Monthly for $6.99 (10% off)</b> : <b>Premium Monthly for $16.99 (10% off)</b>}
        </button>
      </div>  
      <label style={{ paddingRight: '5px' }}>
        <br />
        <b>Note: Please make sure to use and put your Account Email <span style={{color: 'rgb(0, 119, 255)'}}>{userEmail}</span></b> when making the payment to get the Premium license for this email account.
        The Premium serivce will be linked to the email address you enter and use during the payment. <br /><br />
        After you finish the purchase, please click the "Already purchased and refresh" button below to reload the UI and get the subscription.
      </label>
      <div className="ui divider"></div>
      { isPremiumService && 
      <label style={{ paddingRight: '5px', color: 'gray' }}>Need more and want to upgrade to Enterprise service? 
        Click the button below and see more offers!</label> }
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button id="seeEnterpriseButtonId" style={{ margin: '20px 0px 20px', width: '50%' }} 
          className="ui basic blue button" onClick={() => (isPremiumService ? goToEnterpriseOffers() : goToPremiumOffers())}>
          {isPremiumService ? 'See Enterprise offers' : 'See Premium offers'}
        </button>
      </div>
      <label style={{ paddingRight: '5px', color: 'gray' }}>
        Have more questions, or want to purchase a license for a group of people or the whole team/company?
        Please contact us at <a href="https://www.aiworksuite.com/contact-us">aiworksuite.com/contact-us</a>.
        We are happy to answer your questions or discuss related offers.
      </label>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button id="tryPremiumLaterButtonId" style={{ margin: '30px 0px 10px', width: '40%' }} 
          className="ui basic button" onClick={closeModal}>
          Try Premium later
        </button>
        <button style={{ margin: '30px 15px 10px', width: '50%' }} className="ui green basic button"
          onClick={(event) => {updateSubscriptionState(event.target); closeModal(); } }>
          Already Purchased and refresh
        </button>
      </div>
    </div>
  </Modal>);

}

export function PurchaseUIElements(activePurchasePlanShown, userEmail) {
  return (<div>
    {(activePurchasePlanShown === Utils.PURCHASE_PLAN.PREMIUM) && <div>
      <label style={{fontSize: '19px', fontWeight: '400', marginTop: '30px'}}>WorkGPT will help you deliver high quality work quickly. It will save you many hours of creating, 
      editing, completing tasks and work with the Premium service.<br></br><br></br> In addition, Premium service offers</label>
      <ul className="ui list" style={{fontSize: '17px'}}>
        <li><b>No limitation</b> for credit usage of AI WorkGPT and request rate per minute. </li>
        <li><b>Best AI models</b> to utilize, ChatGPT, Google Bard PaLM 2, Meta LIama 2 etc.</li>
        <li><b>400 GPT requests every day</b> for you to use!</li>
        <li><b>Long GPT input support</b> with 25000 characters each time. </li>
        <li><b>Many great features</b> to help you improve and create content and images, custom GPT, history etc</li>
        <li><b>Faster response</b> with dedicated GPT server. </li>
        <li><b>Priority support</b> for your issues and requests. </li>
        <li><b>Multiple surfaces supported</b> to use anywhere and anytime, WebApp, Google Workspace addon, Chrome Extension. </li>
      </ul>
      <p style={{fontSize: '17px'}}>Current Premium offers available:</p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          id="yearlyPremiumButtonId"
          style={{ margin: '15px 0px 15px', width: '90%', maxWidth: '500px', minHeight: '45px', 
          backgroundColor: 'rgb(0, 119, 255)', color: 'white' }}
          onClick={() => onOpenYearlyPremiumSubscriptionLink(userEmail)}
        >
          <b>$4.16 / month with Premium Year for $49.99 (50% off)</b>
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button id="monthlyPremiumButtonId"
          style={{ margin: '0px 0px 10px', width: '90%', maxWidth: '500px', minHeight: '40px', 
          backgroundColor: '#06ADEF', color: 'white' }}
          onClick={() => onOpenMonthlyPremiumSubscriptionLink(userEmail)}
        >
          <b>Premium Monthly for $6.99 (10% off)</b>
        </button>
      </div>
    </div>}

    {(activePurchasePlanShown === Utils.PURCHASE_PLAN.ENTERPRISE) && <div>
      <label style={{fontSize: '19px', fontWeight: '400', marginTop: '30px'}}>WorkGPT will help you deliver high quality work quickly. It will save you many hours of creating, 
      editing, completing tasks and work with the Premium service.<br></br><br></br> In addition, Premium service offers</label>
      <ul className="ui list" style={{fontSize: '17px'}}>
        <li><b>No limitation</b> for credit usage of AI WorkGPT and request rate per minute. </li>
        <li><b style={{color: 'red'}}>2500 GPT requests every day</b> for you to use!</li>
        <li><b>Best AI models</b> to choose, ChatGPT, Google Bard PaLM 2, Meta LIama 2 etc.</li>
        <li><b>Long GPT input support</b> with 25000 characters each time. </li>
        <li><b>Many great features</b> to help you improve and create content and images, custom GPT, history etc</li>
        <li><b>Faster response</b> with dedicated GPT server. </li>
        <li><b>Priority support</b> for your issues and requests. </li>
        <li><b>Priority access</b> to advanced Enterprise features. </li>
        <li><b>Multiple surfaces supported</b> to use anytime and anywhere, WebApp, Google Workspace addon, Chrome Extension. </li>
      </ul>

      <p style={{fontSize: '17px'}}>Current Premium offers available:</p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          id="yearlyPremiumButtonId"
          style={{ margin: '0px 0px 10px', width: '90%', maxWidth: '500px', minHeight: '45px', 
          backgroundColor: 'rgb(0, 119, 255)', color: 'white' }}
          onClick={() => onOpenYearlyEnterpriseSubscriptionLink(userEmail)}
        >
          <b>$14.99 / month with Enterprise Year for $179.99 (20% off)</b>
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button id="monthlyPremiumButtonId"
          style={{ margin: '15px 0px 15px', width: '90%', maxWidth: '500px', minHeight: '40px', 
          backgroundColor: '#06ADEF', color: 'white' }}
          onClick={() => onOpenMonthlyEnterpriseSubscriptionLink(userEmail)}
        >
          <b>Enterprise Monthly for $16.99 (10% off)</b>
        </button>
      </div>
    </div>}


    <label style={{ paddingRight: '5px', fontSize: '17px' }}>
      <br />
      <b>Note: Please make sure to use and put your Account Email <span style={{color: 'rgb(0, 119, 255)'}}>{userEmail}</span></b> when making the payment to get the Premium license for this email account.
      The Premium serivce will be linked to the email address you enter and use during the payment. <br /><br />
      After you finish the purchase, please click the "Already purchased and refresh" button below to reload the UI and get the subscription.
    </label>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <button style={{ margin: '20px 15px 50px', width: '300px' }} className="ui green basic button"
        onClick={(event) => {updateSubscriptionState(event.target); } }>
        Already Purchased and refresh
      </button>
    </div>
  </div>
  )

}