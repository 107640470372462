import { Auth } from 'aws-amplify';

export async function signUp(email, password) {
  try {
    const { user } = await Auth.signUp({
      email,
      password,
      attributes: {
        email,          // optional
        // phone_number,   // optional - E.164 number convention
        // other custom attributes 
      },
      autoSignIn: { // optional - enables auto sign in after user is confirmed
        enabled: true,
      }
    });
  } catch (error) {
    console.log('error signing up:', error);
  }
}

export async function signIn(email, password) {
  try {
    return await Auth.signIn(email, password);
  } catch (error) {
    console.log('error signing in', error);
    return null;
  }
}

// Send confirmation code to user's email
export async function forgotPassword(username) {
  try {
    await Auth.forgotPassword(username);
    //console.log(data);
  } catch(err) {
    console.log(err);
  }
}

// This method can be used to check if a user is logged in when the page is loaded.
// It will throw an error if there is no user logged in. 
// This method should be called after the Auth module is configured or the user is logged in.
export async function currentAuthenticatedUser() {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    return user;
  } catch(err) {
    console.log("get currentAuthenticatedUser() error", err);
    return null;
  }
};

export async function currentSession() {
  try {
    const session = await Auth.currentSession();
    return session;
  } catch(err) {
    console.log("currentSession() get session error", err);
    return null;
  }
};

export async function currentUserInfo() {
  try {
    const userInfo = await Auth.currentUserInfo();
   // console.log("current session is", session);
    return userInfo;
  } catch(err) {
    console.log("get currentUserInfo error", err);
    return null;
  }
}

export async function updateUserAttributes(user, attributes) {
  await Auth.updateUserAttributes(user, attributes);
}